import { NotificationsFilters } from 'shared/api/__generated__';
import { useInfiniteList } from 'shared/lib/hooks/useInfiniteList';

import { NotificationGetList } from '../api/NotificationGetList.gql';
import { parseNotificationItem } from './parseNotificationItem';

export const useNotificationsList = (filters: NotificationsFilters) => {
  const list = useInfiniteList(
    NotificationGetList,
    {
      filters,
      pagination: {
        limit: 10,
        offset: 0,
      },
    },
    (res) => {
      const { items, total } = res.notifications.getMy;

      return {
        items: items.map(parseNotificationItem),
        total,
      };
    },
  );

  return list;
};

import { HostDetailsPage, HostsPage } from 'pages/Host';

import { PermissionType } from 'shared/api/authApi/types';
import { HostsRouteNames, HostsRoutePaths } from 'shared/const/router';

import { TRouteProps } from './types';

export const HostsRoutes: Record<HostsRouteNames, TRouteProps> = {
  [HostsRouteNames.HOSTS]: {
    path: HostsRoutePaths.getRouteHosts(),
    element: <HostsPage />,
    isAuth: true,
    needRules: [PermissionType['HOST.GET_FILTERED_LIST']],
  },
  [HostsRouteNames.HOST_DETAILS_INFO]: {
    path: HostsRoutePaths.getRouteHostDetailsInfo(':id'),
    element: <HostDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['HOST.GET_ONE']],
  },
  [HostsRouteNames.HOST_DEVICES]: {
    path: HostsRoutePaths.getRouteHostDetailsDevices(':id'),
    element: <HostDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['HOST.GET_ONE']],
  },
  [HostsRouteNames.HOST_REPORT]: {
    path: HostsRoutePaths.getRouteHostDetailsReport(':id', ':filter'),
    element: <HostDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['HOST.GET_ONE']],
  },
};

import { InboundDocumentType } from '../../__generated__';

export const DocumentMappedType = {
  [InboundDocumentType.CLIENT_APPEAL]: 'Официальное обращение клиента',
  [InboundDocumentType.HEALTH_CERTIFICATE]: 'Справка о состоянии здоровья',
  [InboundDocumentType.ON_SITE_EXAM_PROTOCOL]:
    'Акт медицинского освидетельствования',
  [InboundDocumentType.INJURY_CERTIFICATE]: 'Справка о травме',
  [InboundDocumentType.CHEMOTHERAPY_CERTIFICATE]:
    'Справка о ХТИ (химиотерапии)',
  [InboundDocumentType.CONCLUSION_OF_PRELIMINARY_OR_PERIODIC_MEDICAL_EXAM]:
    'Заключение предварительного или периодического медицинского осмотра',
};

import { OrgUnitDetailsPage, OrgUnitsPage } from 'pages/OrgUnit';

import { PermissionType } from 'shared/api/authApi/types';
import { OrgUnitsRouteNames, OrgUnitsRoutePaths } from 'shared/const/router';

import { TRouteProps } from './types';

export const OrgUnitsRoutes: Record<OrgUnitsRouteNames, TRouteProps> = {
  [OrgUnitsRouteNames.ORG_UNITS]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnits(),
    element: <OrgUnitsPage />,
    isAuth: true,
    needRules: [PermissionType['ORG_UNIT.GET_FILTERED_LIST']],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_INFO]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsInfo(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['ORG_UNIT.GET_ONE']],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_ORGS]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsOrgs(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['ORG_UNIT.GET_ONE']],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_EMPLOYEES]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsEmployees(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['ORG_UNIT.GET_ONE']],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_HOSTS]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsHosts(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['ORG_UNIT.GET_ONE']],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_HISTORY]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsHistory(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['ORG_UNIT.GET_ONE']],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_EXAMS]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsExams(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['ORG_UNIT.GET_ONE']],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_SETTINGS]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsSettings(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['ORG_UNIT.GET_ONE']],
  },
};

import { PATH_TO_APP } from '../const';

const USERS_ROOT = `${PATH_TO_APP}/users`;

export const getRouteUsers = () => `${USERS_ROOT}`;
export const getRouteUserDetailsInfo = (id: string) => `${USERS_ROOT}/${id}`;
export const getRouteUserDetailsMedData = (id: string) =>
  `${USERS_ROOT}/${id}/meddata`;
export const getRouteUserDetailsHistory = (id: string) =>
  `${USERS_ROOT}/${id}/history`;
export const getRouteUserDetailsSettings = (id: string) =>
  `${USERS_ROOT}/${id}/settings`;

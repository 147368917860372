import { TOKEN_EXPIRATION_MARGIN } from 'shared/const/auth';

import { getExpiration } from './getExpiration';
import { TTokenIsExpiredParams } from './tokens.types';

/**
 * Проверка токена на необходимость обновления
 * NOTE: время истечения проверятся с учетом заложенного gap на обновление
 */
export const needUpdateTokens = (args: TTokenIsExpiredParams) => {
  const tokenExp = getExpiration(args).millis();
  const updateDeadline = Date.now() + TOKEN_EXPIRATION_MARGIN.millis();

  return tokenExp <= updateDeadline;
};

import { useSelector } from 'react-redux';

import {
  getCertificate,
  getDocumentsToSign,
  getIsCertificateError,
  getIsSigning,
  getIsSigningError,
  getSignedDocuments,
  signingActions,
} from 'processes/documentSigning';
import { getIsConnectionError } from 'processes/documentSigning/model/selectors/getIsConnectionError';

import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { Button } from 'shared/ui/buttons/Button';

import { SigningDropdownContent } from './SigningDropdownContent';

interface SigningDropdownProps {
  setAnchorEl: (val: Nullable<HTMLButtonElement>) => void;
  setIsModalOpen: (val: boolean) => void;
}

export const SigningDropdown = (props: SigningDropdownProps) => {
  const { setAnchorEl, setIsModalOpen } = props;
  const documentsToSign = useSelector(getDocumentsToSign);
  const documentsSigned = useSelector(getSignedDocuments);
  const isSigning = useSelector(getIsSigning);
  const isSigningError = useSelector(getIsSigningError);
  const isCertificateError = useSelector(getIsCertificateError);
  const isConnectionError = useSelector(getIsConnectionError);
  const { orgUnitName, serial, expiresAt } = useSelector(getCertificate);

  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    setAnchorEl(null);
    setIsModalOpen(true);
  };

  const handleResumeSigning = () => {
    dispatch(signingActions.resumeSigning());
  };

  if (isConnectionError) {
    return (
      <SigningDropdownContent
        title='В соединении с сервером произошла ошибка'
        description='Выберите сертификат и попробуйте подключиться снова'
        orgUnitName={orgUnitName}
        validTo={expiresAt}
        serial={serial}
      >
        <Button
          color='error'
          variant='outlined'
          onClick={handleOpenModal}
          size='small'
        >
          Выберите сертификат
        </Button>
      </SigningDropdownContent>
    );
  }

  if (isSigningError && !isCertificateError) {
    return (
      <SigningDropdownContent
        title='Подпись документов приостановлена!'
        description='Причина: повторяющиеся ошибки. Кликните, чтобы возобновить подписание'
        orgUnitName={orgUnitName}
        validTo={expiresAt}
        serial={serial}
      >
        <Button
          color='primary'
          variant='contained'
          onClick={handleResumeSigning}
          size='small'
        >
          Возобновить подписание
        </Button>
      </SigningDropdownContent>
    );
  }

  if (isCertificateError) {
    return (
      <SigningDropdownContent
        title='Подпись документов приостановлена!'
        description='Необходимо выбрать сертификат'
        orgUnitName={orgUnitName}
        validTo={expiresAt}
        serial={serial}
      >
        <Button
          color='error'
          variant='outlined'
          onClick={handleOpenModal}
          size='small'
        >
          Выберите сертификат
        </Button>
      </SigningDropdownContent>
    );
  }

  if (documentsToSign.length !== documentsSigned.length && isSigning) {
    return (
      <SigningDropdownContent
        title={`Подписываются ${documentsSigned.length + 1} из ${
          documentsToSign.length
        }`}
        description='Документы сформированы автоматически. Действий не требуют'
        orgUnitName={orgUnitName}
        validTo={expiresAt}
        serial={serial}
      />
    );
  }

  if (documentsToSign.length === documentsSigned.length) {
    return (
      <SigningDropdownContent
        title='Все подписано!'
        description='Документы сформированы автоматически. Действий не требуют'
        orgUnitName={orgUnitName}
        validTo={expiresAt}
        serial={serial}
      >
        <Button variant='outlined' onClick={handleOpenModal} size='small'>
          Выбрать другой сертификат
        </Button>
      </SigningDropdownContent>
    );
  }

  return null;
};

import { ReportPage } from 'pages/Report';

import { PermissionType } from 'shared/api/authApi/types';
import { ReportRouteNames, ReportRoutePaths } from 'shared/const/router';

import { TRouteProps } from './types';

export const ReportRoutes: Record<ReportRouteNames, TRouteProps> = {
  [ReportRouteNames.REPORT]: {
    path: ReportRoutePaths.getRouteReport(),
    element: <ReportPage />,
    isAuth: true,
    needRules: [PermissionType['REPORT.GET_FILTERED_LIST']],
  },
};

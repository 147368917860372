export enum PermissionType {
  'ACCOUNT.BOTS' = 'account.method.bots',
  'ACCOUNT.DISABLE' = 'account.method.disable',
  'ACCOUNT.ENABLE' = 'account.method.enable',
  'ACCOUNT.GET_CODE' = 'account.method.get_code',
  'ACCOUNT.GET_FILTERED_LIST' = 'account.method.get_filtered_list',
  'ACCOUNT.GET_ONE' = 'account.method.get_one',
  'ACCOUNT.REMOVE' = 'account.method.remove',
  'ACCOUNT.SET_ACCESS' = 'account.method.set_access',
  'ACCOUNT.SET_ROLE' = 'account.method.set_role',
  'ACCOUNTS.METHOD.IMPORT' = 'accounts.method.import',
  'BAN.FORCE_UNBAN' = 'ban.method.force_unban',
  'BAN.GET_LIST' = 'ban.method.get_list',
  'BAN.UNBAN' = 'ban.method.unban',
  'BILLING_PROFILE.SET' = 'billing_profile.method.set',
  'CRASHLYTICS.GET_LIST' = 'crashlytics.method.get_list',
  'CRASHLYTICS.INIT_REPORT_FILE' = 'crashlytics.method.init_report_file',
  'EXAM.GET_FILTERED_LIST' = 'exam.method.get_filtered_list_all',
  'EXAM.GET_ONE' = 'exam.method.get_one',
  'EXAM.RESOLVE' = 'exam.method.resolve',
  'FAKER.METHOD.ADD_TEST_EXAM' = 'faker.method.add_test_exam',
  'FAKER.METHOD.TEST_ENROLL' = 'faker.method.test_enroll',
  'GUEST_LINKS.METHOD.SET' = 'guest_links.method.set',
  'HOST_TYPES.GET_LIST' = 'host_types.method.get_list',
  'HOST.ADD_INVITATION' = 'host.method.add_invitation',
  'HOST.CANCEL_INVITATION' = 'host.method.cancel_invitation',
  'HOST.DISABLE' = 'host.method.disable',
  'HOST.ENABLE' = 'host.method.enable',
  'HOST.GET_FILTERED_LIST' = 'host.method.get_filtered_list',
  'HOST.GET_ONE' = 'host.method.get_one',
  'HOST.UNENROLL' = 'host.method.unenroll',
  'HOST.VERIFICATION.METHOD.SYNC_HOST' = 'verification.method.sync_host',
  'IMPORTS.METHOD.GET_FILTERED_LIST' = 'imports.method.get_filtered_list',
  'INDOCS.ADD' = 'indocs.method.add',
  'INDOCS.APPROVE' = 'indocs.method.approve',
  'INDOCS.ARCHIVE' = 'indocs.method.archive',
  'INDOCS.UPDATE' = 'indocs.method.update',
  'MED_OPERATOR_PROFILE.SET' = 'med_operator_profile.method.set',
  'ORG_UNIT.ADD' = 'org_unit.method.add',
  'ORG_UNIT.DISABLE' = 'org_unit.method.disable',
  'ORG_UNIT.ENABLE' = 'org_unit.method.enable',
  'ORG_UNIT.GET_FILTERED_LIST' = 'org_unit.method.get_filtered_list',
  'ORG_UNIT.GET_ONE' = 'org_unit.method.get_one',
  'ORG_UNIT.MOVE' = 'org_unit.method.move',
  'ORG_UNIT.UPDATE' = 'org_unit.method.update',
  'PEOPLE.ADD' = 'people.method.add',
  'PEOPLE.SET_ATTRIBUTES' = 'people.method.set_attributes',
  'PEOPLE.SET_MEDICAL_PROFILE' = 'people.method.set_medical_profile',
  'PEOPLE.SET_PERSONAL_DATA' = 'people.method.set_personal_data',
  'PERSON.ATTRIBUTES.THRESHOLDS' = 'person.attributes.thresholds',
  // 'PERSON.INDOCS' = 'person.attributes.indocs', // XXX: Что это?
  'PERSON.OUTDOCS' = 'person.attributes.outbound_document',
  'PHOTO.ADD' = 'photo.method.add',
  'PHOTO.ARCHIVE' = 'photo.method.archive',
  'PHOTO.VERIFY' = 'photo.method.verify',
  'RELEASE_POINT_PROFILE.SET' = 'release_point_profile.method.set',
  'REPORT.FETCH_RESULT' = 'report.method.fetch_result',
  'REPORT.GET_DEFINITIONS' = 'report.method.get_definitions',
  'REPORT.GET_FILTERED_LIST' = 'report.method.get_filtered_list',
  'REPORT.ORDER_REPORT' = 'report.method.order_report',
  'ROLE_MANAGEMENT.GET_PERMISSION_GROUP' = 'role_management.method.get_permission_group',
  'ROLE_MANAGEMENT.GET_PERMISSION_GROUPS' = 'role_management.method.get_permission_groups',
  'ROLE_MANAGEMENT.GET_ROLE' = 'role_management.method.get_role',
  'ROLE_MANAGEMENT.GET_ROLES' = 'role_management.method.get_roles',
  'RULES.DELETE' = 'rules_mutations.method.delete',
  'RULES.DELETE_ALL' = 'rules_mutations.method.delete_all',
  'RULES.DISABLE_ALL' = 'rules_mutations.method.disable_all',
  'RULES.ENABLE_ALL' = 'rules_mutations.method.enable_all',
  'RULES.UPDATE' = 'rules_mutations.method.update',
  'RULES.UPDATE_ALL' = 'rules_mutations.method.update_all',
  'SERVICING_PROFILE.SET' = 'servicing_profile.method.set',
  'SETTINGS.SET_EXAM_TYPE_SETTINGS' = 'settings.method.set_exam_type_settings',
  'SIGNING_CORE.SIGN' = 'signing_core.method.sign',
  'SUBSCRIPTIONS.CREATE' = 'subscriptions_mutations.method.create',
  'SUBSCRIPTIONS.CREATE_ALL' = 'subscriptions_mutations.method.create_all',
  'SUBSCRIPTIONS.DELETE' = 'subscriptions_mutations.method.delete',
  'SUBSCRIPTIONS.DELETE_ALL' = 'subscriptions_mutations.method.delete_all',
  'SUBSCRIPTIONS.DISABLE_ALL' = 'subscriptions_mutations.method.disable_all',
  'SUBSCRIPTIONS.ENABLE_ALL' = 'subscriptions_mutations.method.enable_all',
  'SUBSCRIPTIONS.GET_LIST' = 'subscriptions_queries.method.get_filtered_list',
  'SUBSCRIPTIONS.GET_LIST_ALL' = 'subscriptions_queries.method.get_filtered_list_all',
  'SUBSCRIPTIONS.UPDATE' = 'subscriptions_mutations.method.update',
  'SUBSCRIPTIONS.UPDATE_ALL' = 'subscriptions_mutations.method.update_all',
  // 'ACCOUNT.PEOPLE' = 'account.method.people',
  // 'BILLING_PROFILE.DROP' = 'billing_profile.method.drop',
  // 'BOT.ADD' = 'bot.method.add',
  // 'BOT.SET_RESPONSIBLES' = 'bot.method.set_responsibles',
  // 'BOT.UPDATE' = 'bot.method.update',
  // 'CERTIFICATES.ASSERT' = 'certificates.method.assert',
  // 'EXAM_BASE.INDICATORS' = 'exam_base.attribute.indicators',
  // 'EXAM_BASE.RESULTS' = 'exam_base.attribute.results',
  // 'EXAM_BASE.THRESHOLDS' = 'exam_base.attribute.thresholds',
  // 'EXAM.GET_CURRENT_OR_LAST' = 'exam.method.get_current_or_last',
  // 'EXAM.OUTDOCS' = 'processed_exam.attribute.outdocs', // XXX: Что это?
  // 'EXAM.PROLONG_DEADLINE' = 'exam.method.prolong_deadline',
  // 'EXAM.TAKE' = 'exam.method.take',
  // 'IMPORTS.METHOD.FETCH_RESULT' = 'imports.method.fetch_result',
  // 'INDOCS.DECLINE' = 'indocs.method.decline',
  // 'INDOCS.GET_FILTERED_LIST' = 'indocs.method.get_filtered_list',
  // 'INDOCS.GET_ONE' = 'indocs.method.get_one',
  // 'MED_OPERATOR_PROFILE.DROP' = 'med_operator_profile.method.drop',
  // 'ORG_UNIT.ALLOWED_ROLES' = 'org_unit.attributes.allowed_roles',
  // 'ORG_UNIT.ALLOWED_TYPES' = 'org_unit.method.allowed_types',
  // 'ORG_UNIT.BILLING_PROFILE' = 'org_unit.attributes.billing_profile',
  // 'ORG_UNIT.CHANGE_TYPE' = 'org_unit.method.change_type',
  // 'ORG_UNIT.EXAMS' = 'org_unit.attributes.exams',
  // 'ORG_UNIT.MED_OPERATOR_PROFILE' = 'org_unit.attributes.med_operator_profile',
  // 'ORG_UNIT.REMOVE' = 'org_unit.method.remove',
  // 'ORG_UNIT.REQUISITES' = 'org_unit.attributes.requisites',
  // 'ORG_UNIT.SERVICING_PROFILE' = 'org_unit.attributes.servicing_profile',
  // 'ORG_UNIT.SETTINGS' = 'org_unit.attributes.settings',
  // 'PERSON.CERTIFICATES' = 'person.attribute.certificates', // XXX: Что это?
  'THRESHOLD.SET_FOR_EMPLOYEE' = 'threshold.method.set_for_employee',
  // 'RELEASE_POINT_PROFILE.DROP' = 'release_point_profile.method.drop',
  // 'SERVICING_PROFILE.DROP' = 'servicing_profile.method.drop',
  // 'THRESHOLD.SET_FOR_ORG_UNIT' = 'threshold.method.set_for_org_unit',
}

import { StorageKey } from './storage.types';

export const storageAvailable = () => {
  try {
    return !!localStorage;
  } catch (e) {
    return false;
  }
};

export const getBrowserStorage = (key: StorageKey) => {
  try {
    if (!storageAvailable()) throw Error('localStorage недоступен');
    const item = localStorage.getItem(key);

    return item;
  } catch (err) {
    console.error('Не удалось получить данные из хранилища', err);

    return null;
  }
};

export const setBrowserStorage = (key: StorageKey, value: string) => {
  try {
    if (!storageAvailable()) throw Error('localStorage недоступен');
    localStorage.setItem(key, value);
  } catch (err) {
    console.error('Не удалось записать данные в хранилище', err);
  }
};

export const removeBrowserStorage = (key: StorageKey) => {
  try {
    if (!storageAvailable()) throw Error('localStorage недоступен');
    localStorage.removeItem(key);
  } catch (err) {
    console.error('Не удалось удалить данные из хранилища', err);
  }
};

export const clearBrowserStorage = () => {
  try {
    if (!storageAvailable()) throw Error('localStorage недоступен');
    localStorage.clear();
  } catch (err) {
    console.error('Не удалось очистить хранилище', err);
  }
};

import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { HostReportType } from 'shared/api/__generated__';
import { getRouteHostDetailsReport } from 'shared/const/router/hosts/hosts.paths';
import { useApolloListQuery } from 'shared/lib/hooks/useApolloListQuery';

import { hostCrashlyticsGetList } from '../api';
import { HostReportFilter } from '../types/HostsFilterVariables';

const hostReportTypesByFilter = {
  [HostReportFilter.ALL]: [],
  [HostReportFilter.ERRORS]: [HostReportType.CRASH_REPORT],
  [HostReportFilter.LOGS]: [HostReportType.LOG],
};

const defaultPagination = {
  limit: 6,
  offset: 0,
};

export const useHostReport = (hostId: string) => {
  const params = useParams();
  const navigate = useNavigate();

  const [pagination, setPagination] = useState(defaultPagination);

  const variables = useMemo(
    () => ({
      filters: {
        hostId,
        type: params.filter
          ? hostReportTypesByFilter[params.filter as HostReportFilter] || []
          : [],
      },
      pagination,
    }),
    [hostId, params, pagination],
  );

  const { loading, data, error, isFullLoaded, refetch, refetchAll } =
    useApolloListQuery(hostCrashlyticsGetList, variables, (res) => {
      const page = res?.crashlytics.getList;

      return {
        items: page?.items || [],
        total: page?.total || 0,
      };
    });

  const resetPaginationAndRefetch = useCallback(() => {
    setPagination(defaultPagination);
    refetch();
  }, [setPagination, refetch]);

  const loadMore = useCallback(() => {
    if (loading || isFullLoaded) return;
    setPagination((prev) => {
      const newPagination = { ...prev };
      newPagination.offset = data.items.length;
      return newPagination;
    });
  }, [loading, isFullLoaded, data.items.length]);

  const changeFilters = useCallback(
    (filter: HostReportFilter) => {
      setPagination((prev) => ({
        ...prev,
        offset: 0,
      }));

      navigate(getRouteHostDetailsReport(hostId, filter), { replace: true });
    },
    [hostId, navigate],
  );

  return {
    loading,
    data,
    error,
    loadMore,
    changeFilters,
    variables,
    isFullLoaded,
    refetch,
    refetchAll,
    setPagination,
    resetPaginationAndRefetch,
  };
};

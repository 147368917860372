import { PATH_TO_APP } from '../const';

const ORG_UNITS_ROOT = `${PATH_TO_APP}/org_units`;

export const getRouteOrgUnits = () => `${ORG_UNITS_ROOT}`;
export const getRouteOrgUnitDetailsInfo = (id: string) =>
  `${ORG_UNITS_ROOT}/${id}`;
export const getRouteOrgUnitDetailsOrgs = (id: string) =>
  `${ORG_UNITS_ROOT}/${id}/orgs`;
export const getRouteOrgUnitDetailsEmployees = (id: string) =>
  `${ORG_UNITS_ROOT}/${id}/employees`;
export const getRouteOrgUnitDetailsHosts = (id: string) =>
  `${ORG_UNITS_ROOT}/${id}/hosts`;
export const getRouteOrgUnitDetailsHistory = (id: string) =>
  `${ORG_UNITS_ROOT}/${id}/history`;
export const getRouteOrgUnitDetailsExams = (id: string) =>
  `${ORG_UNITS_ROOT}/${id}/exams`;
export const getRouteOrgUnitDetailsSettings = (id: string) =>
  `${ORG_UNITS_ROOT}/${id}/settings`;

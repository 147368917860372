interface FakeExamPostParams {
  key: string;
  employeeId: string;
  hostId: string;
}

interface FakeExamPostResponse {
  success: boolean;
  violation: string[];
}

export const fakeExamPost = async (
  params: FakeExamPostParams,
): Promise<FakeExamPostResponse> => {
  const result = await fetch(
    `${import.meta.env.__TEST_PANEL_API_URL__}/exams`,
    {
      method: 'POST',
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
    },
  );

  return result.json();
};

import { FileMeta } from './downloadFileByLink.types';

/**
 * Извлечение названия файла из заголовка s3
 * NOTE: при запросе с localhost доступ к заголовку Content-Disposition будет ограничен
 */
const getFilenameFromResponse = (response: Response) => {
  try {
    if (response.ok) {
      const contentDispositionHeader = response.headers.get(
        'Content-Disposition',
      );

      if (contentDispositionHeader) {
        const [, filename] =
          contentDispositionHeader.match(/filename=([^;]+)/) || [];

        if (filename) {
          return decodeURIComponent(filename);
        }
      }
    }
  } catch (err) {
    console.error('Не удалось получить название файла из заголовков', err);
  }

  return null;
};

/**
 * Формирование названия файла на основе мета-данных
 */
const createFilenameFromMeta = (meta: FileMeta) => {
  const { name, ext } = meta;
  const extension = ext ? `.${ext}` : '';

  return `${name}${extension}` || null;
};

/**
 * Извлечение названия файла из url
 */
const createFilenameFromUrl = (fileLink: string | URL) => {
  try {
    const urlObj = new URL(fileLink);
    return urlObj.pathname.split('/').pop() ?? '';
  } catch (err) {
    console.error('Не удалось получить название файла из url', err);
  }

  return null;
};

/**
 * Скачивание файла по ссылке
 */
export const downloadFileByLink = async (
  /** Ссылка на файл */
  fileLink: string | URL,
  /** Мета-данные файла */
  meta?: FileMeta,
) => {
  try {
    const response = await fetch(fileLink);
    const blob = await response.blob();

    const downloadLink = document.createElement('a');

    downloadLink.download =
      // пытаемся получить имя файла из заголовка ...
      getFilenameFromResponse(response) ||
      // ... или из мета-данных
      (meta && createFilenameFromMeta(meta)) ||
      // ... пытаемся извлечь из url
      createFilenameFromUrl(fileLink) ||
      // ... и если уж ничего не удалось, то указываем:
      'untitled_file';

    downloadLink.href = URL.createObjectURL(blob);

    downloadLink.click();
  } catch (err) {
    console.error('Не удалось скачать файл', err);
  }
};

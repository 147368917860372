import { EmployeeImportPage } from 'pages/EmployeeImport';

import { PermissionType } from 'shared/api/authApi/types';
import {
  EmployeeImportRouteNames,
  EmployeeImportRoutePaths,
} from 'shared/const/router';

import { TRouteProps } from './types';

export const EmployeeImportRoutes: Record<
  EmployeeImportRouteNames,
  TRouteProps
> = {
  [EmployeeImportRouteNames.EMPLOYEE_IMPORT]: {
    path: EmployeeImportRoutePaths.getEmployeeImport(),
    element: <EmployeeImportPage />,
    isAuth: true,
    needRules: [PermissionType['ACCOUNTS.METHOD.IMPORT']],
  },
};

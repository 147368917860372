import { IlClose } from '@mc/react-icons/il';
import { IconButton, Stack } from '@mui/material';
import { useMemo, useState } from 'react';

import { classNames } from 'shared/lib/utils/classNames';

import type { ImageContainerProps } from '../types/ImageContainer';

import { IconWrapper } from '../../../helpers/IconWrapper';
import { EmptyPhoto } from './EmptyPhoto';
import cls from './ImageContainer.module.scss';

export const ImageContainer = (props: ImageContainerProps) => {
  const {
    src,
    alt,
    type,
    variant = 'default',
    customIcon,
    maskSlot,
    size,
    circle,
    borderRadius,
    grey,
    contain,
    withBackdrop,
    ghost,
    emptyStyle,
    uuid,
    onClick,
    onClose,
  } = props;

  const _borderRadius = circle ? '50%' : borderRadius;
  const _ghostStyle = ghost
    ? {
        border: 'none',
        backgroundColor: 'transparent',
      }
    : {};
  const _emptyStyle = { ..._ghostStyle, ...emptyStyle };

  const _size = useMemo(() => {
    if (typeof size === 'object') {
      return { width: size.width, height: size.height };
    }

    return { width: size, height: size };
  }, [size]);

  const _src = useMemo(() => {
    if (typeof src === 'string') return src;
    if (src instanceof File) return URL.createObjectURL(src);
    if (src instanceof URL) return src.href;
    return undefined;
  }, [src]);

  const [hasError, setHasError] = useState(false);

  return (
    <Stack
      className={classNames(cls.ImageContainer, {
        [cls.Clickable]: !!onClick,
        [cls.WithBackdrop]: withBackdrop,
      })}
      style={{
        borderRadius: _borderRadius,
        ..._size,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick || (() => {})}
    >
      {src && !hasError ? (
        <img
          className={classNames(cls.Image, {
            [cls.Grey]: grey,
            [cls.Contain]: contain,
          })}
          src={_src}
          alt={alt}
          onError={() => setHasError(true)}
        />
      ) : (
        <EmptyPhoto
          style={_emptyStyle}
          type={type}
          variant={variant}
          uuid={uuid}
          customIcon={customIcon}
        />
      )}

      {maskSlot}

      {onClose && (
        <IconButton className={cls.Remove} onClick={onClose}>
          <IconWrapper className={cls.IconWrapper} Svg={IlClose} />
        </IconButton>
      )}
    </Stack>
  );
};

import { IlShield } from '@mc/react-icons/il';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { getCanMakeActionRule } from 'processes/user';

import { PermissionType } from 'shared/api/authApi/types';
import { classNames } from 'shared/lib/utils/classNames';
import { Dropdown } from 'shared/ui/buttons/Dropdown';
import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import clsContainer from '../NavbarMenuItemContainer/NavbarMenuItemContainer.module.scss';
import cls from './NavbarAdminpanelMenu.module.scss';

export const NavbarAdminpanelMenu = () => {
  const hasAccessToPermissionGroups = useSelector(
    getCanMakeActionRule(
      PermissionType['ROLE_MANAGEMENT.GET_PERMISSION_GROUPS'],
    ),
  );
  const hasAccessToRoles = useSelector(
    getCanMakeActionRule(PermissionType['ROLE_MANAGEMENT.GET_ROLES']),
  );

  const hasAccessToHostTypesGetList = useSelector(
    getCanMakeActionRule(PermissionType['HOST_TYPES.GET_LIST']),
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const isActive = location.pathname.includes('adminpanel');

  const getContainerClass = () => {
    if (isActive) return cls.AdminPanelAnchorActive;
    if (isOpen) return cls.AdminPanelAnchorOpen;
    return cls.AdminPanelAnchor;
  };

  return (
    <Dropdown
      items={[
        {
          id: 'roles',
          content: <Typography variant='t2'>Управление ролями</Typography>,
          onClick: () => navigate('/adminpanel/roles'),
          disabled: !hasAccessToPermissionGroups,
        },
        {
          id: 'groups',
          content: <Typography variant='t2'>Группы разрешений</Typography>,
          onClick: () => navigate('/adminpanel/groups'),
          disabled: !hasAccessToRoles,
        },
        {
          id: 'firmware',
          content: <Typography variant='t2'>Версии прошивок</Typography>,
          onClick: () => navigate('/adminpanel/firmware'),
          disabled: !hasAccessToHostTypesGetList, // XXX: заменить на корректные пермишены
        },
      ]}
      trigger={
        <Stack
          className={classNames(clsContainer.Item, {}, [getContainerClass()])}
        >
          <Stack
            className={cls.AdminPanelContainer}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <IconWrapper Svg={IlShield} />
            <Typography variant='h4' className={cls.Title}>
              Админпанель
            </Typography>
          </Stack>
        </Stack>
      }
      onClose={() => {
        setIsOpen(false);
      }}
      onTrigger='hover'
    />
  );
};

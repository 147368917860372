import { useMutation } from '@apollo/client';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { Certificate } from 'shared/lib/external/cryptoPro';

import { signingActions } from 'processes/documentSigning';
import { useNotifications } from 'processes/notifications/ui/Notifications.context';
import { getFullName } from 'processes/user';

import { SIGNING_ASSERT_CERTIFICATE } from 'shared/api/signingApi';
import {
  createDetachedSignature,
  createHash,
  getUserCertificates,
} from 'shared/lib/external/cryptoPro';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { getBrowserStorage, setBrowserStorage } from 'shared/lib/utils/storage';
import { Button } from 'shared/ui/buttons/Button';
import { Typography } from 'shared/ui/contents/Typography';
import { Loader } from 'shared/ui/helpers/Loader';
import { Dialog } from 'shared/ui/modals/Dialog';

import { parseSubject } from '../lib/utils';
import { CertificateCard } from './CertificateCard';
import cls from './CertificateSelectModal.module.scss';
import { ErrorScreenContent } from './ErrorScreenContent';

/**
 * Проверка, что сертификат подключен в систему и может подписывать документы
 */
const checkCertIsWorking = async (cert: Certificate) => {
  if (!cert) throw new Error('Certificate is not provided');

  const hash = await createHash('Hello world');
  await createDetachedSignature(cert.thumbprint, hash);
};

interface CertificateSelectModalProps {
  open: boolean;
  onClose: () => void;
  onCertificateChosen?: (state: { success: boolean }) => void;
  submitTitle?: string;
}

export const CertificateSelectModal = (props: CertificateSelectModalProps) => {
  const { open, onClose, onCertificateChosen, submitTitle } = props;
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [value, setValue] = useState<Nullable<Certificate>>(null);
  const [loadingCerts, setLoadingCerts] = useState(false);
  const [loadingAssert, setLoadingAssert] = useState(false);
  const [isPluginError, setIsPluginError] = useState(false);

  const certInUse = getBrowserStorage('CERTIFICATE');

  const [assertCertificate] = useMutation(SIGNING_ASSERT_CERTIFICATE);

  const dispatch = useAppDispatch();
  const notificationsActions = useNotifications();

  const fullName = useSelector(getFullName);

  const validList = certificates.filter((el) => {
    const parsed = parseSubject(el.subjectName);
    return Boolean(parsed?.G && parsed?.SN);
  });

  useEffect(() => {
    if (!loadingCerts) {
      setLoadingCerts(true);
      getUserCertificates()
        .then((res: Certificate[]) => {
          setCertificates(res);
          if (certInUse) {
            setValue(res.find((el) => certInUse === el.thumbprint) || null);
          }
        })
        .catch((err) => {
          console.error('Ошибка при запросе сертификатов: ', err);
          setIsPluginError(true);
        })
        .finally(() => setLoadingCerts(false));
    }
  }, []);

  const handleSubmit = async () => {
    if (!value) return;
    try {
      setLoadingAssert(true);
      dispatch(signingActions.setStatus('loading'));

      await checkCertIsWorking(value);

      const asserted = await assertCertificate({
        variables: {
          input: {
            serial: value.thumbprint,
            issuer: value.issuerName,
            subject: value.subjectName,
            issuedAt: value.validFrom,
            expiresAt: value.validTo,
          },
        },
      });
      if (asserted.errors) {
        dispatch(signingActions.setStatus('error'));
        notificationsActions.showErrorNotification({
          title: 'Ошибка Сертификата',
          message: 'Не удалось запустить цикл подписания документов',
        });
        return;
      }
      const cert = asserted.data?.signing.certificates.assert.certificate;
      if (cert) {
        const subject = parseSubject(value.subjectName);
        dispatch(
          signingActions.setCertificate({
            serial: value.thumbprint,
            certificateId: cert.id,
            orgUnitName: subject.CN,
            subject,
            issuedAt: value.validFrom,
            expiresAt: value.validTo,
          }),
        );
        dispatch(signingActions.setCertificateError(false));
        dispatch(signingActions.setIsSigningError(false));
        dispatch(signingActions.tickCounter());
        dispatch(signingActions.setIsConnectionError(false));
        dispatch(signingActions.setStatus('active'));

        onCertificateChosen?.({ success: true });
        setBrowserStorage('CERTIFICATE', cert.serial);
      } else {
        dispatch(signingActions.setStatus('notSet'));
      }

      onClose();
    } catch (err) {
      dispatch(signingActions.setStatus('error'));
      console.error('Не удалось использовать выбранный сертификат', err);
      dispatch(signingActions.setCertificateError(true));

      onCertificateChosen?.({ success: false });
      notificationsActions.showErrorNotification({
        title: 'Ошибка Сертификата',
        message: 'Не удалось использовать выбранный сертификат.',
      });
    } finally {
      setLoadingAssert(false);
    }
  };

  const isLoadedCerts =
    !loadingCerts && !isPluginError && !!validList.length && !loadingAssert;

  return (
    <Dialog open={open} onClose={onClose}>
      <Stack gap='1.5rem' p='2.25rem 7rem'>
        <Stack alignItems='center'>
          <Typography variant='h1'>Выбор сертификата</Typography>
          <Typography variant='h3'>
            Выберите сертификат для автоматического запуска процесса подписи
            документов
          </Typography>
        </Stack>

        {isLoadedCerts && (
          <Stack gap='1rem'>
            {validList.map((el) => {
              const parsed = parseSubject(el.subjectName);
              return (
                <CertificateCard
                  key={el.thumbprint}
                  certificate={el}
                  value={value}
                  onChange={setValue}
                  isDisabled={Boolean(
                    parsed.SN &&
                      parsed.G &&
                      `${parsed.SN.toLowerCase()} ${parsed.G.toLowerCase()}` !==
                        fullName.toLowerCase(),
                  )}
                />
              );
            })}
          </Stack>
        )}

        {isPluginError && (
          <ErrorScreenContent
            title='Не удалось получить доступ к Crypto Pro плагину'
            description='Убедитесь, что плагин CryptoPro и приложение CryptoPro CSP
            установлены и работают корректно'
          />
        )}

        {!validList.length && !isPluginError && !loadingCerts && (
          <ErrorScreenContent
            title='Ни один токен не найден'
            description='Если в списке не отобразился нужный токен, попробуйте заново
          вставить токен и обновить страницу'
          />
        )}

        {loadingCerts && (
          <Stack alignItems='center' justifyContent='center' textAlign='center'>
            <Loader />
            <Typography variant='h3'>Подключаемся...</Typography>
            <Typography variant='t2' className={cls.GreyText} px='5rem'>
              Если в списке не отобразился нужный токен, попробуйте заново
              вставить токен и обновить страницу
            </Typography>
          </Stack>
        )}

        {loadingAssert && (
          <Stack alignItems='center' justifyContent='center' textAlign='center'>
            <Loader />
            <Typography variant='h3'>Запускаем цикл подписи...</Typography>
            <Typography variant='t2' className={cls.GreyText} px='5rem'>
              Сейчас проводится проверка возможности подписи документов и
              регистрация сертификата в системе
            </Typography>
          </Stack>
        )}

        <Stack gap='12px'>
          {isLoadedCerts && (
            <Stack flexDirection='row' justifyContent='center' gap='1rem'>
              <Typography variant='t3' className={cls.GreyText} align='center'>
                Для проверки работоспособности сертификата при запуске будет
                <br />
                произведена попытка подписи произвольной строки.
              </Typography>
            </Stack>
          )}
          <Stack flexDirection='row' justifyContent='center' gap='1rem'>
            <Button variant='outlined' onClick={onClose}>
              Отмена
            </Button>
            <Button disabled={loadingCerts || !value} onClick={handleSubmit}>
              {submitTitle || 'Выбрать и запустить процесс'}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

import { classNames } from 'shared/lib/utils/classNames';

import cls from './IconWrapper.module.scss';

interface IconWrapperProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
  Svg: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}

/**
 * Компонент-обертка для иконок, которая приводит width и height к базовым размерам из Figma
 */
export const IconWrapper = (props: IconWrapperProps) => {
  const { className, Svg, ...otherProps } = props;

  return (
    <Svg
      {...otherProps}
      className={classNames(cls.IconWrapper, {}, [className])}
    />
  );
};

import {
  IlGlobe2 as GlobeIcon,
  IlSetting as SettingIcon,
  IlUser2 as AvatarIcon,
} from '@mc/react-icons/il';
import { Stack, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getRolePermissions } from 'processes/user';

import { PermissionType } from 'shared/api/authApi/types';
import { ColorModeContext } from 'shared/lib/context/ColorModeContext';
import { classNames } from 'shared/lib/utils/classNames';
import { Dropdown } from 'shared/ui/buttons/Dropdown';

import { NavbarAccountControl } from '../NavbarAccountControl';
import { NavbarControlItem } from '../NavbarControlItem';
import { NavbarNotificationsControl } from '../NavbarNotificationsControl';
import { NavbarSigningControl } from '../NavbarSigningControl';
import cls from './NavbarControls.module.scss';

const NavbarControlSettings = () => {
  // Изменение темы
  const theme = useTheme();
  const { mode } = theme.palette;
  const colorMode = useContext(ColorModeContext);

  // Изменение языка
  const { t, i18n } = useTranslation();
  const changeLanguage = async () => {
    await i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru');
  };

  return (
    <Dropdown
      trigger={<NavbarControlItem key='settings' icon={SettingIcon} />}
      items={[
        {
          id: 'changeTheme',
          content: mode === 'light' ? 'Выключить свет' : 'Включить свет',
          onClick: () => colorMode?.toggleColorMode(),
          icon: mode === 'light' ? SettingIcon : AvatarIcon,
          notCloseAfterClick: true,
        },
        {
          id: 'changeLanguage',
          content: t('Change language'),
          onClick: () => changeLanguage(),
          icon: GlobeIcon,
          notCloseAfterClick: true,
        },
      ]}
      onTrigger='click'
    />
  );
};

export const NavbarControls = () => {
  const permissions = useSelector(getRolePermissions);
  const canSigningControl = permissions.has(
    PermissionType['SIGNING_CORE.SIGN'],
  );

  return (
    <Stack className={classNames(cls.NavbarControls)}>
      {canSigningControl && <NavbarSigningControl />}
      <NavbarNotificationsControl />
      {/* <NavbarControlItem key='search' icon={SearchIcon} /> */}
      {/* <NavbarControlItem key='notification' icon={NotificationIcon} /> */}
      <NavbarAccountControl />
      {/* <NavbarControlSettings /> */}
    </Stack>
  );
};

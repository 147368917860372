type HEX = string;

export interface FakeExamItem {
  key: string;
  name: string;
  description: string;
  color: HEX;
}

interface FakeExamsGetResponse {
  fakes: FakeExamItem[];
}

export const fakeExamsGet = async (): Promise<FakeExamsGetResponse> => {
  const result = await fetch(
    `${import.meta.env.__TEST_PANEL_API_URL__}/exams`,
    {
      method: 'GET',
    },
  );

  return result.json();
};

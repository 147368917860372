import {
  IlAndroidSmartphone as PakIcon,
  IlBarChart as ReportIcon,
  IlHome1 as OrgIcon,
  IlLoader1 as ExamIcon,
  IlUserRectangle1 as MedCabinetIcon,
} from '@mc/react-icons/il';
import { createSelector } from '@reduxjs/toolkit';

import { getRolePermissions } from 'processes/user';

import { PermissionType } from 'shared/api/authApi/types';
import {
  ExamsRoutePaths,
  HostsRoutePaths,
  MedCabinetRoutePaths,
  OrgUnitsRoutePaths,
  ReportRoutePaths,
} from 'shared/const/router';

import { NavbarAccountsMenu } from '../../ui/NavbarAccountsMenu/NavbarAccountsMenu';
import { NavbarAdminpanelMenu } from '../../ui/NavbarAdminpanelMenu/NavbarAdminpanelMenu';
import { NavbarMenuList } from '../../ui/NavbarMenuItem/NavbarMenuItem.types';

export const getNavbarItems = createSelector(
  getRolePermissions,
  (permissions) => {
    const checkPermission = (
      permission: PermissionType | PermissionType[],
      item: NavbarMenuList[number],
    ) => {
      if (Array.isArray(permission)) {
        return permission.some((x) => permissions.has(x)) ? [item] : [];
      }
      return permissions.has(permission) ? [item] : [];
    };

    const navbarItems: NavbarMenuList = [
      // {
      //   path: GlobalRoutePaths.getRouteMain(),
      //   text: 'Меню',
      //   Icon: MenuIcon,
      // },
      // {
      //   path: GlobalRoutePaths.getRouteMain(),
      //   text: 'Дашборд',
      //   Icon: RequestIcon,
      // },
      ...checkPermission(PermissionType['EXAM.GET_FILTERED_LIST'], {
        path: ExamsRoutePaths.getRouteExams(),
        text: 'Осмотры',
        Icon: ExamIcon,
      }),
      ...checkPermission(PermissionType['HOST.GET_FILTERED_LIST'], {
        path: HostsRoutePaths.getRouteHosts(),
        text: 'ПАК',
        Icon: PakIcon,
      }),
      ...checkPermission(PermissionType['EXAM.RESOLVE'], {
        path: MedCabinetRoutePaths.getRouteMedCabinet(),
        text: 'Медкабинет',
        Icon: MedCabinetIcon,
      }),
      ...checkPermission(PermissionType['ORG_UNIT.GET_FILTERED_LIST'], {
        path: OrgUnitsRoutePaths.getRouteOrgUnits(),
        text: 'Организации',
        Icon: OrgIcon,
      }),
      ...checkPermission([PermissionType['REPORT.GET_FILTERED_LIST']], {
        path: ReportRoutePaths.getRouteReport(),
        text: 'Отчёты',
        Icon: ReportIcon,
      }),
      ...checkPermission(
        [
          PermissionType['ACCOUNT.GET_FILTERED_LIST'],
          PermissionType['IMPORTS.METHOD.GET_FILTERED_LIST'],
        ],
        <NavbarAccountsMenu />,
      ),
      ...checkPermission(
        [
          PermissionType['ROLE_MANAGEMENT.GET_PERMISSION_GROUPS'],
          PermissionType['ROLE_MANAGEMENT.GET_ROLES'],
          PermissionType['HOST_TYPES.GET_LIST'],
        ],
        <NavbarAdminpanelMenu />,
      ),
    ];

    return navbarItems;
  },
);

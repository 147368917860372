import {
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  FormHelperText,
} from '@mui/material';
import {
  Fragment,
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { useThrottle } from 'shared/lib/hooks/useThrottle';

import { Typography } from '../../../contents/Typography';
import { Loader } from '../../../helpers/Loader';
import { Autocomplete } from '../../selects/Autocomplete';
import { TextField } from '../TextField';
import cls from './UniversalAutocomplete.module.scss';

export interface SelectOption {
  id: string;
  label: string;
}

export type AutocompleteValue<
  T,
  Multi extends boolean | undefined,
> = Multi extends true ? T[] : T | null;

interface UniversalAutocompleteMultipleProps<TOption> {
  /** Выбор нескольких значений */
  multiple: true;
  /** Если multiple: true, value должно быть массивом TOption */
  value: AutocompleteValue<TOption, true>;
  /** Обработчик изменения значения */
  onChangeValue: (value: AutocompleteValue<TOption, true>) => void;
}

interface UniversalAutocompleteSingleProps<TOption> {
  /** Выбор нескольких значений */
  multiple?: false;
  /** Если multiple: false, value должно быть TOption или null */
  value: AutocompleteValue<TOption, false>;
  /** Обработчик изменения значения */
  onChangeValue: (value: AutocompleteValue<TOption, false>) => void;
}

export type UniversalAutocompleteProps<
  TOption,
  TValue = string,
  Multi extends boolean | undefined = undefined,
> = {
  /** Элементы выпадающеего списка */
  options: TOption[];
  /** Обработчик события прокрутки до конца списка */
  loadMore?: () => void;
  /** Функция получения значения элемента списка */
  getOptionValue?: (option: TOption) => TValue;
  /** Функция получения текста для отображения выбранного элемента в текстовом поле при multiple === false. Используется также для получения текста элемента списка, если renderOption не определен */
  getOptionLabel?: (option: TOption) => string;
  /** Функция рендеринга элемента списка */
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TOption,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  /** Включает фильтрацию элементов списка. Используется нативная для MUI.Autocomplete фильтрация, или кастомная, если передать filterOptions */
  enableFilterOptions?: boolean;
  /** Кастомная функция фильтрации элементов списка. Используется если enableFilterOptions === true */
  filterOptions?: (
    options: TOption[],
    state: FilterOptionsState<TOption>,
  ) => TOption[];

  /** Поле неактивно, если true */
  disabled?: boolean;
  /** Отображается индикатор обязательного заполнения '*', если true */
  required?: boolean;
  /** В конце выпадающего списка отображается прелоадер, если true */
  isLoading?: boolean;
  /** В конце выпадающего списка отображается текст 'все элементы загружены', если true */
  isFullyLoaded?: boolean;
  /** Поле подсвечивается красным, если true */
  hasError?: boolean;

  /** Плейсхолдер текстового поля */
  placeholder?: string;
  /** Текст заголовка поля */
  label?: string;
  /** Текст под полем. Красного цвета, если hasError === true */
  helperText?: string;

  /** Отключает ввод в текстовое поле, если true */
  disableSearch?: boolean;
  /** Значение текстового поля */
  search: string;
  /** Текст, который отображается в выпадающем списке, если массив элементов списка options пуст */
  searchErrorText?: string;
  /** Обработчик изменения значения текстового поля */
  onChangeSearch?: (value: string) => void;
  /** Текстовое поле очищается при закрытии выпадающего списка, если true */
  clearSearchOnClose?: boolean;

  /** Обработчик события открытия выпадающего списка */
  onOpenHandler?: () => void;
  /** Обработчик события закрытия выпадающего списка */
  onCloseHandler?: () => void;

  /** Удалять ли значения при нажатии backspace */
  deleteOnBackspace?: boolean;
  /** Отключает крестик для очистки */
  disableClearable?: boolean;
  /** Функция для вычисления неактивности элемента списка */
  getOptionDisabled?: (option: TOption) => boolean;
} & TernaryUnion<
  Multi,
  UniversalAutocompleteMultipleProps<TOption>,
  UniversalAutocompleteSingleProps<TOption>
>;

function disabledFilterOptions<TOption>(options: TOption[]): TOption[] {
  return options;
}

export function UniversalAutocomplete<TOption, TValue>(
  props: UniversalAutocompleteProps<TOption, TValue, undefined>,
) {
  const {
    value,
    onChangeValue,

    options,
    loadMore,
    getOptionValue = (option: TOption) => (option as SelectOption).id,
    getOptionLabel = (option: TOption) => (option as SelectOption).label,

    disabled,
    required,
    multiple,
    isLoading,
    isFullyLoaded,

    placeholder,
    label,
    hasError,
    helperText,

    disableSearch,
    search,
    searchErrorText,
    onChangeSearch,
    clearSearchOnClose,

    onOpenHandler,
    onCloseHandler,

    filterOptions,
    renderOption: renderOptionExternal,

    enableFilterOptions,
    deleteOnBackspace,
    disableClearable,
    getOptionDisabled,
  } = props;

  /*
   * DROPDOWN SCROLL HANDLER
   */
  const listInnerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const callbackFnToThrottle = useCallback(() => {
    if (isFullyLoaded || !loadMore) return;
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, offsetHeight } = listInnerRef.current;
      const isNearBottom =
        Math.ceil(scrollTop + offsetHeight) + 150 >= scrollHeight;

      if (isNearBottom) loadMore();
    }
  }, [loadMore, isFullyLoaded]);
  const handleOnScroll = useThrottle(callbackFnToThrottle, 500);
  const ListboxProps = useMemo(
    () => ({
      onScroll: handleOnScroll,
      ref: listInnerRef,
    }),
    [handleOnScroll],
  );

  /** Подгрузка данных, до момента появления scrollBar */
  useEffect(() => {
    if (isFullyLoaded || !loadMore) return;
    if (listInnerRef.current) {
      const { scrollHeight, offsetHeight } = listInnerRef.current;
      const isNeedLoadMore = scrollHeight === offsetHeight;

      if (isNeedLoadMore) loadMore();
    }
  }, [isFullyLoaded, loadMore]);

  /*
   * INPUT
   */
  const renderInput = useCallback(
    ({ inputProps, ...params }: AutocompleteRenderInputParams) => (
      <TextField
        required={required}
        error={hasError}
        helperText={helperText}
        {...params}
        label={label}
        size='small'
        placeholder={placeholder || undefined}
        inputProps={{
          ...inputProps,
          readOnly: disableSearch,
        }}
        onKeyDown={(event: KeyboardEvent) => {
          if (
            !deleteOnBackspace &&
            (event.key === 'Backspace' || event.key === 'Delete')
          ) {
            event.stopPropagation();
          }
        }}
      />
    ),
    [
      required,
      hasError,
      helperText,
      label,
      placeholder,
      disableSearch,
      deleteOnBackspace,
    ],
  );

  const inputValue = useMemo(() => {
    if (disableSearch || !search) {
      return value && !multiple ? getOptionLabel(value as TOption) : '';
    }
    return search;
  }, [disableSearch, search, value, multiple, getOptionLabel]);

  const handleInputChange = useCallback(
    (
      _: React.SyntheticEvent,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      // исключаем сброс по событию reset, чтобы поиск не сбрасывался при выборе элемента
      // по этой причине вычисление значения текстового поля происходит в useMemo inputValue (см. выше)
      if (reason !== 'reset') {
        onChangeSearch?.(newInputValue);
      }
    },
    [onChangeSearch],
  );

  /*
   * OPTIONS
   */
  const renderOption = useCallback(
    (
      optionProps: React.HTMLAttributes<HTMLLIElement>,
      option: TOption,
      state: AutocompleteRenderOptionState,
    ) => {
      const key = `listItem-${getOptionValue?.(option)}`;
      return (
        <Fragment key={key}>
          {renderOptionExternal ? (
            renderOptionExternal(optionProps, option, state)
          ) : (
            <li {...optionProps}>{getOptionLabel?.(option)}</li>
          )}
          {state.index === options.length - 1 && (
            <>
              {isFullyLoaded ? (
                <Typography variant='h4' textAlign='center'>
                  --- все элементы загружены ---
                </Typography>
              ) : null}
              {isLoading && <Loader />}
            </>
          )}
        </Fragment>
      );
    },
    [
      renderOptionExternal,
      getOptionValue,
      getOptionLabel,
      isLoading,
      isFullyLoaded,
      options,
    ],
  );

  const isOptionEqualToValue = useCallback(
    (currentValue: TOption, el: TOption) =>
      getOptionValue(currentValue) === getOptionValue(el),
    [getOptionValue],
  );

  /*
   * HANDLERS
   */
  const handleChangeSingle = useCallback(
    (_: React.SyntheticEvent, newValue: AutocompleteValue<TOption, false>) => {
      if (!multiple) {
        onChangeValue(newValue);
      }
    },
    [onChangeValue, multiple],
  );
  const handleChangeMultiple = useCallback(
    (_: React.SyntheticEvent, newValue: AutocompleteValue<TOption, true>) => {
      if (multiple) {
        onChangeValue(newValue);
      }
    },
    [onChangeValue, multiple],
  );

  const handleOpen = useCallback(() => {
    onOpenHandler?.();
  }, [onOpenHandler]);

  const handleClose = useCallback(() => {
    if (clearSearchOnClose) {
      onChangeSearch?.('');
    }
    onCloseHandler?.();
  }, [clearSearchOnClose, onChangeSearch, onCloseHandler]);

  /*
   * CONST
   */
  const renderTags = useCallback(() => null, []);

  const noOptionsText = useMemo(
    () =>
      searchErrorText ? (
        <FormHelperText error>{searchErrorText}</FormHelperText>
      ) : undefined,
    [searchErrorText],
  );

  const autocompleteProps = {
    // Состояние
    loading: isLoading,
    loadingText: <Loader />,
    noOptionsText,
    disabled,
    // Поиск
    renderInput,
    inputValue,
    onInputChange: handleInputChange,
    // Options
    options,
    isOptionEqualToValue,
    getOptionLabel,
    renderOption,
    filterOptions: enableFilterOptions ? filterOptions : disabledFilterOptions,
    // Пропсы списка
    ListboxProps,
    // Действия
    onOpen: handleOpen,
    onClose: handleClose,
    // Мульти
    renderTags,
    disableCloseOnSelect: multiple,
    disableClearable: multiple ? true : disableClearable,
    getOptionDisabled,
    classes: {
      option: cls.Option,
      listbox: cls.Listbox,
    },
  };

  return multiple ? (
    <Autocomplete
      {...autocompleteProps}
      value={value || []}
      multiple
      onChange={handleChangeMultiple}
    />
  ) : (
    <Autocomplete
      {...autocompleteProps}
      value={value || null}
      onChange={handleChangeSingle}
    />
  );
}

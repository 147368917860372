import { Checkbox as MUICheckbox, CheckboxProps } from '@mui/material';

import { classNames } from 'shared/lib/utils/classNames';

type CheckboxSize = 'large' | 'medium' | 'small';

interface CustomCheckboxProps extends Omit<CheckboxProps, 'size'> {
  size?: CheckboxSize;
}

export const Checkbox = (props: CustomCheckboxProps) => {
  const { size, className, ...otherProps } = props;

  return (
    <MUICheckbox
      {...otherProps}
      className={classNames('', {}, [className, size])}
    />
  );
};

import { Radio as MUIRadio, RadioProps } from '@mui/material';

import { classNames } from 'shared/lib/utils/classNames';

type RadioSize = 'large' | 'medium' | 'small';

interface CustomRadioProps extends Omit<RadioProps, 'size'> {
  size?: RadioSize;
}

export const Radio = (props: CustomRadioProps) => {
  const { size, className, ...otherProps } = props;

  return (
    <MUIRadio
      {...otherProps}
      className={classNames('', {}, [className, size])}
    />
  );
};

import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { AlertTypes } from 'shared/ui/helpers/Alert';

export interface NotificationState {
  open: boolean;
  type: AlertTypes;
  title: string;
  message: string;
  button?: {
    text: string;
  } & (
    | {
        onClick: () => void;
      }
    | {
        onClickPath: string;
      }
  );
}

export type ShowTypeNotificationOptions = {
  title?: NotificationState['title'];
  message: NotificationState['message'];
  button?: NotificationState['button'];
};

export type ShowNotificationOptions = ShowTypeNotificationOptions & {
  type: NotificationState['type'];
};

export type ShowNotification = (options: ShowNotificationOptions) => void;
export type ShowTypeNotification = (
  options: ShowTypeNotificationOptions,
) => void;

const initialState = {
  open: false,
  message: '',
  title: '',
  type: AlertTypes.INFO,
  button: {
    text: '',
    onClickPath: '',
  },
};

export const NotificationsContext = createContext<{
  state: NotificationState;
  hideNotification: () => void;
  showNotification: ShowNotification;
  showErrorNotification: ShowTypeNotification;
  showInfoNotification: ShowTypeNotification;
  showSuccessNotification: ShowTypeNotification;
}>({
  state: initialState,
  hideNotification: () => {},
  showNotification: () => {},
  showErrorNotification: () => {},
  showInfoNotification: () => {},
  showSuccessNotification: () => {},
});

export const NotificationsContextProvider = (props: {
  children: ReactNode;
}) => {
  const { children } = props;

  const [state, setState] = useState<NotificationState>(initialState);

  const showNotification = useCallback((options: ShowNotificationOptions) => {
    setState((_state) => ({
      ..._state,
      open: true,
      ...options,
      title:
        options.title ||
        {
          [AlertTypes.SUCCESS]: 'Успешно',
          [AlertTypes.ERROR]: 'Ошибка',
          [AlertTypes.INFO]: 'Информация',
        }[options.type],
    }));
  }, []);

  const showErrorNotification = useCallback(
    (options: ShowTypeNotificationOptions) =>
      showNotification({ type: AlertTypes.ERROR, ...options }),
    [showNotification],
  );
  const showInfoNotification = useCallback(
    (options: ShowTypeNotificationOptions) =>
      showNotification({ type: AlertTypes.INFO, ...options }),
    [showNotification],
  );
  const showSuccessNotification = useCallback(
    (options: ShowTypeNotificationOptions) =>
      showNotification({ type: AlertTypes.SUCCESS, ...options }),
    [showNotification],
  );

  const hideNotification = useCallback(() => {
    setState(initialState);
  }, []);

  const value = useMemo(
    () => ({
      state,
      hideNotification,
      showNotification,
      showErrorNotification,
      showInfoNotification,
      showSuccessNotification,
    }),
    [
      state,
      hideNotification,
      showNotification,
      showErrorNotification,
      showInfoNotification,
      showSuccessNotification,
    ],
  );

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationState = (): NotificationState =>
  useContext(NotificationsContext).state;

export type NotificationsActions = {
  hideNotification: () => void;
  showNotification: ShowNotification;
  showErrorNotification: ShowTypeNotification;
  showInfoNotification: ShowTypeNotification;
  showSuccessNotification: ShowTypeNotification;
};

export const useNotifications = (): NotificationsActions => {
  const {
    hideNotification,
    showErrorNotification,
    showInfoNotification,
    showNotification,
    showSuccessNotification,
  } = useContext(NotificationsContext);

  return useMemo(
    () => ({
      hideNotification,
      showErrorNotification,
      showInfoNotification,
      showNotification,
      showSuccessNotification,
    }),
    [
      hideNotification,
      showErrorNotification,
      showInfoNotification,
      showNotification,
      showSuccessNotification,
    ],
  );
};

import { useMutation } from '@apollo/client';
import { IlBell } from '@mc/react-icons/il';
import { Stack } from '@mui/system';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useNotifications } from 'processes/notifications/ui/Notifications.context';

import { formatDate } from 'shared/lib/utils/dates';
import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import { NotificationSetIsRead } from '../../api/NotificationSetIsRead.gql';
import { NotificationIndicator } from '../NotificationIndicator';
import cls from './NotificationCard.module.scss';

interface NotificationCardProps {
  id: string;
  header?: {
    text: string;
    value?: string;
    url?: string;
  };
  descriptions?: { text: string }[];
  timestamp: Dayjs;
  payload: {
    text: string;
    value?: string;
    url?: string;
  }[];
  isNew: boolean;
  onClose: () => void;
}

export const NotificationCard = (props: NotificationCardProps) => {
  const { id, isNew, header, descriptions, payload, timestamp, onClose } =
    props;

  const [isUnread, setIsUnread] = useState(isNew);

  const headerText = header?.url
    ? header.text.split('{{value}}')
    : header?.text;
  const notificationsActions = useNotifications();

  const [setIsRead] = useMutation(NotificationSetIsRead, {
    variables: {
      input: {
        ids: [id],
        isRead: isUnread,
      },
    },
  });

  useEffect(() => {
    setIsUnread(isNew);
  }, [isNew]);

  const handleSetIsRead = async () => {
    try {
      const res = await setIsRead();

      if (res.errors) {
        console.error(res.errors);
        throw new Error('Не удалось отметить как прочитанное');
      }

      setIsUnread(!isUnread);
    } catch (err) {
      console.error(err);

      notificationsActions.showErrorNotification({
        message: 'Не удалось отметить как прочитанное',
      });
    }
  };

  return (
    <Stack className={cls.Card}>
      <Stack direction='row' alignItems='center' gap='.25rem'>
        <IconWrapper Svg={IlBell} className={cls.Icon} />
        {header?.url ? (
          <Typography
            variant='h4'
            display='inline'
            flexDirection='row'
            gap='.125rem'
          >
            {headerText && headerText[0]}
            <Link
              to={header.url}
              className={cls.HeaderUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Typography
                variant='h4'
                display='inline'
                className={cls.LinkText}
              >
                {` ${header.value}`}
              </Typography>
            </Link>
            {headerText && headerText[1]}
          </Typography>
        ) : (
          <Typography variant='h4'>{header?.text}</Typography>
        )}
      </Stack>
      <Stack direction='row' gap='.125rem'>
        <Typography variant='t3' fontWeight={500}>
          {descriptions?.map((el) => el.text).join('. ')}
        </Typography>
      </Stack>
      {payload?.map((el, idx) => {
        const key = el.text + idx;
        const preparedText = el?.url ? el.text.split('{{value}}') : el.text;
        return (
          <Stack key={key} direction='row' gap='.125rem' alignItems='center'>
            {el?.url ? (
              <Typography variant='t3'>
                <Typography variant='t3' className={cls.GreyText}>
                  {preparedText[0]}
                </Typography>
                <Link to={el.url} target='_blank' rel='noopener noreferrer'>
                  <Typography variant='t3' className={cls.LinkText}>
                    {el.value}
                  </Typography>
                </Link>
                {preparedText[1]}
              </Typography>
            ) : (
              <Typography variant='t3'>{el.value}</Typography>
            )}
          </Stack>
        );
      })}
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        gap='.5rem'
      >
        <Typography variant='t3' className={cls.GreyText}>
          {formatDate(timestamp, 'DD.MM.YYYY')}
        </Typography>
        <Typography variant='t3' className={cls.GreyText}>
          {formatDate(timestamp, 'HH:mm')}
        </Typography>
        <NotificationIndicator isActive={isUnread} onClick={handleSetIsRead} />
      </Stack>
    </Stack>
  );
};

import { IlUser3 as UsersIcon } from '@mc/react-icons/il';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { getCanMakeActionRule } from 'processes/user';

import { PermissionType } from 'shared/api/authApi/types';
import { EmployeeImportRoutePaths, UsersRoutePaths } from 'shared/const/router';
import { classNames } from 'shared/lib/utils/classNames';
import { Dropdown } from 'shared/ui/buttons/Dropdown';
import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import clsContainer from '../NavbarMenuItemContainer/NavbarMenuItemContainer.module.scss';
import cls from './NavbarAccountsMenu.module.scss';

export const NavbarAccountsMenu = () => {
  const hasAccessToAccounts = useSelector(
    getCanMakeActionRule(PermissionType['ACCOUNT.GET_FILTERED_LIST']),
  );
  const hasAccessToImports = useSelector(
    getCanMakeActionRule(PermissionType['IMPORTS.METHOD.GET_FILTERED_LIST']),
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const isActive = /users|employee-import/gi.test(location.pathname);

  const getContainerClass = () => {
    if (isActive) return cls.AccountsAnchorActive;
    if (isOpen) return cls.AccountsAnchorOpen;
    return cls.AccountsAnchor;
  };

  return (
    <Dropdown
      items={[
        {
          id: 'users',
          content: <Typography variant='t2'>Пользователи</Typography>,
          onClick: () => navigate(UsersRoutePaths.getRouteUsers()),
          disabled: !hasAccessToAccounts,
        },
        {
          id: 'users-import',
          content: <Typography variant='t2'>Импорт</Typography>,
          onClick: () => navigate(EmployeeImportRoutePaths.getEmployeeImport()),
          disabled: !hasAccessToImports,
        },
      ]}
      trigger={
        <Stack
          className={classNames(clsContainer.Item, {}, [getContainerClass()])}
          onClick={() => {
            setIsOpen(true);
            navigate(UsersRoutePaths.getRouteUsers());
          }}
        >
          <Stack className={cls.AccountsContainer}>
            <IconWrapper Svg={UsersIcon} />
            <Typography variant='h4' className={cls.Title}>
              Пользователи
            </Typography>
          </Stack>
        </Stack>
      }
      onClose={() => setIsOpen(false)}
      onTrigger='hover'
    />
  );
};

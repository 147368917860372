import { ApolloError } from '@apollo/client';
import { Stack } from '@mui/system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useIntersection } from 'shared/lib/hooks/useIntersection';
import { classNames } from 'shared/lib/utils/classNames';

import { Button } from '../../../buttons/Button';
import { Loader } from '../../../helpers/Loader';
import { Typography } from '../../Typography';

interface InfiniteListProps<TItem> {
  items: TItem[];
  renderItem: (item: TItem) => ReactNode;
  count: number;
  isFullLoaded: boolean;
  pageSize?: number;
  className?: string;
  isLoading?: boolean;
  error?: ApolloError;
  scrollEndCallback?: () => void;
  emptyList?: ReactNode;
}

const LoaderList = () => {
  const { t } = useTranslation();

  return (
    <Loader>
      {t('Loading list')}
      ...
    </Loader>
  );
};

export const InfiniteList = <TItem,>(props: InfiniteListProps<TItem>) => {
  const {
    items,
    renderItem,
    count,
    isFullLoaded,
    pageSize = 10,
    className,
    isLoading,
    error,
    scrollEndCallback,
    emptyList,
  } = props;

  const { t } = useTranslation();

  const { triggerRef } = useIntersection(() => {
    if (scrollEndCallback) scrollEndCallback();
  });

  const showMoreBtn = !isFullLoaded && !isLoading && (
    <Button variant='outlined' size='small' onClick={scrollEndCallback}>
      Показать ещё
    </Button>
  );

  if (error && !items.length) {
    return <div>{error.message}</div>;
  }

  if (!count && isLoading) {
    // TODO: заменить на скелетоны
    return <LoaderList />;
  }

  return (
    <Stack
      className={classNames('', {}, [className])}
      direction='column'
      gap='16px'
      width='100%'
    >
      {count ? (
        <>
          {items.map(renderItem)}
          {isLoading && <LoaderList />}
          {count / pageSize < 3 ? (
            <div ref={triggerRef} />
          ) : (
            !isFullLoaded && showMoreBtn
          )}
        </>
      ) : (
        emptyList || (
          <Typography textAlign='center'>{t('No results found')}</Typography>
        )
      )}
    </Stack>
  );
};

import { IlAlertCircle, IlCheck, IlClose } from '@mc/react-icons/il';

import { AlertTypes } from '../types';
import cls from '../ui/Alert.module.scss';

export const getAlertClassName = (type: AlertTypes) => {
  switch (type) {
    case AlertTypes.SUCCESS:
      return cls.SuccessAlert;
    case AlertTypes.ERROR:
      return cls.ErrorAlert;
    default:
      return cls.InfoAlert;
  }
};

export const getAlertIcon = (type: AlertTypes) => {
  switch (type) {
    case AlertTypes.SUCCESS:
      return IlCheck;
    case AlertTypes.ERROR:
      return IlClose;
    default:
      return IlAlertCircle;
  }
};

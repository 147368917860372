import { URL } from 'url';

import { useMutation, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getIsLineActive } from 'processes/line';
import { useNotifications } from 'processes/notifications/ui/Notifications.context';

import {
  SIGNING_FILES_FOR_SIGNING,
  SIGNING_SIGN_DOCUMENT,
} from 'shared/api/signingApi';
import {
  createDetachedSignature,
  createHash,
} from 'shared/lib/external/cryptoPro';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useRetryAttempt } from 'shared/lib/hooks/useRetryAttempt';
import { useSubscriptionManager } from 'shared/lib/hooks/useSubscriptionManager';
import { createNewDate } from 'shared/lib/utils/dates';
import { logger } from 'shared/lib/utils/logger';
import { removeBrowserStorage } from 'shared/lib/utils/storage';

import { getCertificate } from '../model/selectors/getCertificateId';
import { getDocumentsToSign } from '../model/selectors/getDocumentsToSign';
import { getIsCertificateError } from '../model/selectors/getIsCertificateError';
import { getSingingCounter } from '../model/selectors/getSingingCounter';
import { signingActions } from '../model/slice/signingSlice';

const signerLog = logger('SIGNER');

const MAX_ATTEMPTS = 3;

// количество ms между попытками подписания
const DELAY_LENGTH = 5000;

const signFile = async (file: ArrayBuffer, serial: string) => {
  try {
    const hash = await createHash(file);
    const signed = await createDetachedSignature(serial, hash);

    const blob = new Blob([signed], {
      type: 'text/plain;charset=utf-8',
    });

    const data = new File([blob], 'file');

    return data;
  } catch (err) {
    console.error(err);
    throw new Error('Не удалось создать файл с подписью');
  }
};

const getFile = async (url: URL) => {
  try {
    const response = await fetch(url);
    const fileAsArrayBuffer = await response.arrayBuffer();
    return fileAsArrayBuffer;
  } catch (err) {
    console.error(err);
    throw new Error('Не удалось скачать файл для подписи');
  }
};

let listIds: string[] = [];

export const DocumentsSubscriber = () => {
  const documentsToSign = useSelector(getDocumentsToSign);
  const { serial, certificateId } = useSelector(getCertificate);
  const tickCounter = useSelector(getSingingCounter);
  const isCertificateError = useSelector(getIsCertificateError);

  useEffect(() => {
    listIds = [];
  }, [certificateId]);

  const isLineActive = useSelector(getIsLineActive);

  const dispatch = useAppDispatch();
  const notificationsActions = useNotifications();

  const { onErrorHandler } = useRetryAttempt();

  const [signDocument] = useMutation(SIGNING_SIGN_DOCUMENT);

  const { skip, run, restart, called } = useSubscriptionManager();

  useEffect(() => {
    run();
  }, [run]);

  // Запускаем, если подписка была разорвана при нахождении в медкабе
  useEffect(() => {
    if (called && isLineActive) run();
  }, [run, called, isLineActive]);

  useSubscription(SIGNING_FILES_FOR_SIGNING, {
    skip,
    variables: {
      input: {
        certificateId,
      },
    },
    onComplete: () => {
      if (isLineActive) {
        signerLog('[COMPLETE]', 'мы на линии - подписываемся снова');
        restart();
      }
    },
    onData: async (res) => {
      if (res.data.data?.filesForSign.__typename === 'Disconnect') {
        signerLog('[DATA]', 'disconnect с бэка');
        if (isLineActive) {
          signerLog('[DATA]', 'мы на линии - подписываемся снова');
          restart();
          return;
        }
        signerLog('[DATA]', 'мы не на линии - не подписываемся');
      }

      const needUpdateToken =
        res.data.data?.filesForSign.__typename ===
        'TokenUpdateSubscriptionEvent';
      if (needUpdateToken) return;

      const { data } = res.data || {};
      const file =
        data?.filesForSign.__typename === 'FileForSign' && data.filesForSign;

      if (!file) return;

      if (listIds.includes(file?.id)) {
        signerLog('ждёт в очереди на подписание');
        return;
      }

      listIds.push(file?.id);
      if (documentsToSign.every((el) => el.id !== file?.id)) {
        dispatch(
          signingActions.addDocumentsToSign({
            file: {
              ...file,
              url: file?.url.toString(),
            },
            id: file?.id,
            status: 'idle',
            attempts: 0,
          }),
        );
      }

      // проверяем, что нет активных или ожидающих подписания документов
      const isSigningCompleted = documentsToSign.every(
        (el) => el.status !== 'idle' && el.status !== 'inProgress',
      );

      if (isSigningCompleted) {
        signerLog('все документы подписаны');
        dispatch(signingActions.tickCounter());
      }
    },
    onError: (err) => {
      signerLog('[ERROR]', err);

      onErrorHandler({
        repeat: restart,
        stop: () => {
          // FIXME: подумать как обойти ограничение apollo и запустить подписку после ошибки
          // Нужно сбросить сертификат, чтобы Apollo обнаружил изменение.
          dispatch(signingActions.clearCertificate());
          dispatch(signingActions.setIsConnectionError(true));
        },
      });
    },
  });

  const signDoc = async () => {
    // ссылка на файл на подпись
    const el = documentsToSign.find(
      (item) => item.status === 'idle' || item.status === 'error',
    );

    if (!el) {
      // если больше нет документов на подпись - очищаем стор
      dispatch(signingActions.clearDocuments());
      return;
    }

    try {
      // если превышено количество попыток прерываем
      if (el.attempts >= MAX_ATTEMPTS) {
        throw new Error('Достигнут лимит попыток подписания');
      }
      // если такой элемент есть - подписываем его
      dispatch(signingActions.setIsSigning(true));

      dispatch(
        signingActions.changeDocumentStatus({
          id: el?.id,
          status: 'inProgress',
        }),
      );

      if (
        !el?.file.url &&
        createNewDate(el.file.deadline).unix() <= createNewDate().unix()
      ) {
        throw new Error('Неверная ссылка или истек срок на скачивание файла');
      }

      // скачиваем и подготавливаем файл для отправки на сервер
      const file = await getFile(el?.file.url);
      let data;

      // дополнительная обработка, на случай ошибок с сертификатом
      try {
        data = await signFile(file!, serial);
      } catch (err) {
        signerLog('[ERROR]', 'подпись документа', err);
        // выставляем ошибки для обозначения критической ошибки при подписании
        dispatch(signingActions.setStatus('error'));
        dispatch(signingActions.setCertificateError(true));
        dispatch(signingActions.setIsSigningError(true));

        notificationsActions.showErrorNotification({
          title: 'Ошибка подписи документов',
          message:
            'Не удалось использовать выбранный сертификат при подписи документа',
        });
        removeBrowserStorage('CERTIFICATE');
        return;
      }

      try {
        const res = await signDocument({
          variables: {
            input: {
              fileId: el.id,
              certificateId,
              bytes: data,
            },
          },
        });

        if (res.errors) {
          dispatch(
            signingActions.changeDocumentStatus({
              id: el.id,
              status: 'error',
            }),
          );
          throw new Error(res.errors.map((i) => i.message).join('. '));
        }

        // обновляем статус файла в сторе
        dispatch(signingActions.addSignedDocuments(el.id));
        dispatch(
          signingActions.changeDocumentStatus({
            id: el.id,
            status: 'signed',
          }),
        );

        signerLog('документ подписан');
        // обновляем счетчик для перехода к следующему элементу
        dispatch(signingActions.tickCounter());
      } catch (err) {
        signerLog('[ERROR]', 'подпись документа', err);
        // выставляем ошибки для обозначения критической ошибки при подписании
        dispatch(signingActions.setStatus('error'));
        dispatch(signingActions.setCertificateError(false));
        dispatch(signingActions.setIsSigningError(true));

        notificationsActions.showErrorNotification({
          title: 'Ошибка подписи документов',
          message: 'Не удалось отправить документ на сервер',
        });
      }
    } catch (err) {
      if (!isCertificateError) {
        dispatch(
          signingActions.changeDocumentStatus({
            id: el.id,
            status: 'error',
          }),
        );

        // если превышено количество попыток, выставляем флаг об ошибках подписания
        // и прерываем подписание, пока не возобновим вручную через дропдаун
        if (el.attempts >= MAX_ATTEMPTS) {
          dispatch(signingActions.setIsSigningError(true));
          dispatch(signingActions.setIsSigning(false));
          notificationsActions.showErrorNotification({
            title: 'Ошибка подписи документов',
            message:
              'Повторяющиеся ошибки при подписании. Возобновите подписание вручную.',
          });
        } else {
          setTimeout(() => {
            dispatch(signingActions.tickCounter());
          }, DELAY_LENGTH);
        }

        signerLog('[ERROR]', 'не удалось подписать файл', err);
      }
    }
  };

  // эффект запускается только при апдейте счетчика
  useEffect(() => {
    signDoc();
    if (
      !documentsToSign.some(
        (el) =>
          el.status === 'idle' || (el.status === 'error' && el.attempts < 3),
      )
    ) {
      dispatch(signingActions.setIsSigning(false));
    }
  }, [tickCounter]);

  return null;
};

import { Chip, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { Button } from '../../buttons/Button';
import { Photo } from '../../contents/Photo';

export interface ChipsProps<TItem> {
  items: TItem[];
  onDelete?: (item: TItem) => void;
  onClear?: () => void;
  getId?: (item: TItem) => any;
  getLabel?: (item: TItem) => string;
  isExpandable?: boolean;
  size?: number;
  isClearable?: boolean;
  getAvatar?: (item: TItem) => string | undefined;
}

type ChipItemDefault = { name: string; label: string };

export function Chips<TItem = ChipItemDefault>(props: ChipsProps<TItem>) {
  const {
    items,
    onDelete,
    onClear,
    getId = (item: TItem) => (item as ChipItemDefault).name,
    getLabel = (item: TItem) => (item as ChipItemDefault).label,
    size = 5,
    isExpandable,
    isClearable,
    getAvatar,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const _items = useMemo(
    () => (isExpanded || !isExpandable ? items : items.slice(0, size)),
    [items, isExpanded, size, isExpandable],
  );

  const chipSize = useMemo(() => (getAvatar ? 'medium' : 'small'), [getAvatar]);

  useEffect(() => {
    if (isExpanded && items.length <= size) {
      setIsExpanded(false);
    }
  }, [isExpanded, items, size]);

  return (
    <Stack direction='column' gap='.25rem'>
      <Stack
        alignSelf='flex-start'
        direction='row'
        gap='.25rem'
        flexWrap='wrap'
      >
        {_items.map((item) => (
          <Chip
            key={getId(item)}
            label={getLabel(item)}
            onDelete={() => onDelete?.(item)}
            size={chipSize}
            variant='filled'
            color='default'
            avatar={
              getAvatar ? (
                <Stack marginX={4} marginY={3}>
                  <Photo size={24} src={getAvatar(item)} />
                </Stack>
              ) : undefined
            }
          />
        ))}
        {isExpandable && !isExpanded && items.length - _items.length > 0 && (
          <Chip
            variant='filled'
            color='lightBlue'
            clickable
            label={`+${items.length - _items.length}`}
            size={chipSize}
            onClick={() => setIsExpanded(true)}
          />
        )}
      </Stack>
      <Stack
        alignSelf='flex-start'
        direction='row'
        gap='.25rem'
        flexWrap='wrap'
      >
        {isExpandable && isExpanded && (
          <Button
            variant='text'
            size='small'
            color='primary'
            onClick={() => setIsExpanded(false)}
            sx={{
              maxHeight: '1.5rem',
              minHeight: '1.5rem',
            }}
          >
            Скрыть
          </Button>
        )}
        {isClearable && !!items.length && (
          <Button
            variant='text'
            size='small'
            color='neutral'
            onClick={() => onClear?.()}
            sx={{
              maxHeight: '1.5rem',
              minHeight: '1.5rem',
            }}
          >
            Сбросить
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

import { Suspense, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router';

import { authSliceActions } from 'processes/auth';

import { PageLoader } from 'widgets/PageLoader';

import { getBrowserStorage } from 'shared/lib/utils/storage';

import { routeConfig } from '../../../config/routes';
import { ErrorBoundary } from '../../error';
import { AuthorizedPage } from './AuthorizedPage';
import { UnauthorizedPage } from './UnauthorizedPage';

export const AppRouter = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const accountType = getBrowserStorage('ACCOUNT_TYPE');

  const makeBGColor = useCallback((pathname: string) => {
    return pathname.includes('login')
      ? document.body.classList.add('bg-color-help')
      : document.body.classList.remove('bg-color-help');
  }, []);

  useEffect(() => {
    makeBGColor(location.pathname);
  }, [makeBGColor, location]);

  useEffect(() => {
    if (
      location.pathname.includes('login') &&
      accountType !== 'NeedToChooseAccount'
    ) {
      dispatch(authSliceActions.clearAccountData());
    }
  }, [location, dispatch, accountType]);

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        {Object.values(routeConfig).map(
          ({ element, path, isAuth, needRules, beforeEach }) => (
            <Route
              key={path}
              path={path}
              element={
                isAuth ? (
                  <AuthorizedPage params={{ needRules, beforeEach }}>
                    <ErrorBoundary>{element}</ErrorBoundary>
                  </AuthorizedPage>
                ) : (
                  <UnauthorizedPage>{element}</UnauthorizedPage>
                )
              }
            />
          ),
        )}
      </Routes>
    </Suspense>
  );
};

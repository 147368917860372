import { styled, Switch, SwitchProps } from '@mui/material';

export const SwitchStyled = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 46,
  height: 24,
  padding: 0,

  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
    boxShadow: 'none',
  },

  '& .MuiSwitch-track': {
    borderRadius: 20,
    opacity: 1,
    backgroundColor: 'var(--color-monochrome-grey-100)',
  },

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    transitionProperty: 'color, transform',
    color: 'var(--color-monochrome-grey-300)',

    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: 'var(--color-blue-100)',
      '& + .MuiSwitch-track': {
        backgroundColor: 'var(--color-blue-000)',
      },
    },

    '&.Mui-focusVisible': {
      '& .MuiSwitch-thumb': {
        color: 'var(--color-blue-000)',
        border: '4px solid var(--color-blue-100)',
      },
    },

    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'var(--color-monochrome-grey-100)',
      },
      '& .MuiSwitch-thumb': {
        color: 'var(--color-monochrome-grey-200)',
      },
    },
  },
}));

import { UserDetailsPage, UsersPage } from 'pages/User';

import { PermissionType } from 'shared/api/authApi/types';
import { UsersRouteNames, UsersRoutePaths } from 'shared/const/router';

import { TRouteProps } from './types';

export const UsersRoutes: Record<UsersRouteNames, TRouteProps> = {
  [UsersRouteNames.USERS]: {
    path: UsersRoutePaths.getRouteUsers(),
    element: <UsersPage />,
    isAuth: true,
    needRules: [PermissionType['ACCOUNT.GET_FILTERED_LIST']],
  },
  [UsersRouteNames.USER_DETAILS_INFO]: {
    path: UsersRoutePaths.getRouteUserDetailsInfo(':id'),
    element: <UserDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['ACCOUNT.GET_ONE']],
  },
  [UsersRouteNames.USER_DETAILS_MED]: {
    path: UsersRoutePaths.getRouteUserDetailsMedData(':id'),
    element: <UserDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['ACCOUNT.GET_ONE']],
  },
  [UsersRouteNames.USER_DETAILS_SETTINGS]: {
    path: UsersRoutePaths.getRouteUserDetailsSettings(':id'),
    element: <UserDetailsPage />,
    isAuth: true,
    needRules: [PermissionType['ACCOUNT.GET_ONE']],
  },
};

import { getDateRange } from 'shared/lib/utils/dates';

import { KindQuickFilter, ResolutionSuccessQuickFilter } from '../types';

export const examsListFilterInitialState = {
  kinds: KindQuickFilter.ALL,
  resolutionSuccess: ResolutionSuccessQuickFilter.ALL,
  // нам необходимо иметь дефолтную дату при инициализации страницы
  dates: {
    from: getDateRange('last24Hours')[0].toISOString(),
    to: getDateRange('last24Hours')[1].toISOString(),
    isChanged: false,
  },
  employees: [],
  owners: [],
  medics: [],
  hosts: [],
  examTypes: [],
  isTest: false,
  isHullIntegrityCompromised: false,
  isAssistantDisabled: false,
  isExternalConditionsDisturbed: false,
  isVideoTimeout: false,
  isProcessingFail: false,
  isPreprocessingFail: false,
  isMedicalDeviceError: false,
  cumulativeResolutions: [],
  remarks: [],
};

export const examsListFilterStateOthersReset = {
  isTest: false,
  isHullIntegrityCompromised: false,
  isAssistantDisabled: false,
  isExternalConditionsDisturbed: false,
  isVideoTimeout: false,
  isProcessingFail: false,
  isPreprocessingFail: false,
  isMedicalDeviceError: false,
  cumulativeResolutions: [],
  remarks: [],
  examTypes: [],
};

export const examsListFilterStateAllExceptDateReset = {
  employees: [],
  owners: [],
  medics: [],
  hosts: [],
  examTypes: [],
  isTest: false,
  isHullIntegrityCompromised: false,
  isAssistantDisabled: false,
  isExternalConditionsDisturbed: false,
  isVideoTimeout: false,
  isProcessingFail: false,
  isPreprocessingFail: false,
  isMedicalDeviceError: false,
  cumulativeResolutions: [],
  remarks: [],
};

export const examsListFilterSelectedItemsInitialState = {
  employees: [],
  owners: [],
  owners2: [],
  medics: [],
  hosts: [],
  examTypes: [],
};

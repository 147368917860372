import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getRolePermissions } from 'processes/user';

import { Navbar } from 'widgets/Navbar';
import {
  ERROR_CODES,
  NotFoundResourceWidget,
} from 'widgets/NotFoundResourceWidget';

interface PermissionsGuardProps {
  children: React.ReactNode;
  needRules?: string[];
}

export const PermissionsGuard = (props: PermissionsGuardProps) => {
  const { children, needRules } = props;

  const userPermissions = useSelector(getRolePermissions);

  const hasRequiredPermissions = useMemo(() => {
    if (!needRules) {
      return true;
    }

    return needRules.some((el) => {
      const hasPermission = userPermissions.has(el);
      return hasPermission;
    });
  }, [needRules, userPermissions]);

  if (!hasRequiredPermissions) {
    return (
      <>
        <Navbar />
        <NotFoundResourceWidget code={ERROR_CODES.NO_ACCESS} />
      </>
    );
  }

  if (!React.isValidElement(children)) {
    console.error('Children of PermissionsGuard must be a valid React element');
    return null;
  }

  return children;
};

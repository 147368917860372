import {
  AdminpanelFirmwarePage,
  AdminpanelGroupPage,
  AdminpanelGroupsPage,
  AdminpanelRolePage,
  AdminpanelRolesPage,
} from 'pages/Adminpanel';

import {
  AdminpanelRouteNames,
  AdminpanelRoutePaths,
} from 'shared/const/router/adminpanel';

import { TRouteProps } from './types';

export const AdminpanelRoutes: Record<AdminpanelRouteNames, TRouteProps> = {
  [AdminpanelRouteNames.ADMINPANEL_GROUPS]: {
    path: AdminpanelRoutePaths.getRouteAdminpanelGroups(),
    element: <AdminpanelGroupsPage />,
    isAuth: true,
  },
  [AdminpanelRouteNames.ADMINPANEL_GROUP]: {
    path: AdminpanelRoutePaths.getRouteAdminpanelGroup(':id'),
    element: <AdminpanelGroupPage />,
    isAuth: true,
  },
  [AdminpanelRouteNames.ADMINPANEL_ROLES]: {
    path: AdminpanelRoutePaths.getRouteAdminpanelRoles(),
    element: <AdminpanelRolesPage />,
    isAuth: true,
  },
  [AdminpanelRouteNames.ADMINPANEL_ROLE]: {
    path: AdminpanelRoutePaths.getRouteAdminpanelRole(':id'),
    element: <AdminpanelRolePage />,
    isAuth: true,
  },
  [AdminpanelRouteNames.ADMINPANEL_FIRMWARE]: {
    path: AdminpanelRoutePaths.getRouteAdminpanelFirmware(),
    element: <AdminpanelFirmwarePage />,
    isAuth: true,
  },
};

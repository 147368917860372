import { AutocompleteRenderOptionState, Stack } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import { Typography } from '../../../contents/Typography';
import { Chips } from '../../../special/Chips';
import { Checkbox } from '../../checkboxes/Checkbox';
import {
  AutocompleteValue,
  SelectOption,
  UniversalAutocomplete,
  UniversalAutocompleteProps,
} from '../../inputs/UniversalAutocomplete';
import cls from './SearchSelectBase.module.scss';

export type SearchSelectOption = SelectOption;

export interface SearchSelectBaseMultipleProps<TOption, TValue> {
  /** Выбор нескольких значений */
  multiple: true;
  /** Обработчик изменения значения */
  onChange: (
    value: AutocompleteValue<TValue, true>,
    options: TOption[],
  ) => void;
}
export interface SearchSelectBaseSingleProps<TOption, TValue> {
  /** Выбор нескольких значений */
  multiple?: false;
  /** Обработчик изменения значения */
  onChange: (
    value: AutocompleteValue<TValue, false>,
    options: TOption[],
  ) => void;
}

export type SearchSelectBaseProps<
  TOption,
  TValue,
  Multi extends boolean | undefined = undefined,
> = Omit<
  UniversalAutocompleteProps<TOption, TValue, Multi>,
  'multiple' | 'onChangeValue' | 'value'
> & {
  /** Выбранные элементы выпадающеего списка (значение поля) */
  selectedOptions: TOption[];
  /** Текст над полем ввода */
  topErrorMessage?: string;
  /** Функция получения текста элемента списка для отображения в chips под полем ввода (для multiple) */
  getChipLabel?: (option: TOption) => string;
  /** Функция получения аватарки элемента списка для отображения в chips под полем ввода (для multiple) */
  getAvatar?: (option: TOption) => string | undefined;
} & TernaryUnion<
    Multi,
    SearchSelectBaseMultipleProps<TOption, TValue>,
    SearchSelectBaseSingleProps<TOption, TValue>
  >;

export function SearchSelectBase<TOption, TValue>(
  props: SearchSelectBaseProps<TOption, TValue, undefined>,
) {
  const {
    selectedOptions,
    multiple,
    onChange,
    topErrorMessage,
    label,
    placeholder,
    renderOption: renderOptionExternal,
    getChipLabel,
    getAvatar,

    disabled,
    getOptionLabel = (option: TOption) => (option as SearchSelectOption).label,
    getOptionValue = (option: TOption) =>
      (option as SearchSelectOption).id as TValue,

    ...restProps
  } = props;

  const _placeholder = useMemo(() => {
    return !selectedOptions.length
      ? placeholder
      : `Выбрано ${selectedOptions.length}`;
  }, [selectedOptions, placeholder]);

  const handleChangeValueSingle = useCallback(
    (newSelectedOption: AutocompleteValue<TOption, false>) => {
      if (!multiple) {
        onChange(
          newSelectedOption ? getOptionValue(newSelectedOption) : null,
          newSelectedOption ? [newSelectedOption] : [],
        );
      }
    },
    [onChange, getOptionValue, multiple],
  );

  const handleChangeValueMultiple = useCallback(
    (newSelectedOptions: AutocompleteValue<TOption, true>) => {
      if (multiple) {
        const newValueOptions = newSelectedOptions.filter((option) => option);
        onChange(newValueOptions.map(getOptionValue), newValueOptions);
      }
    },
    [onChange, getOptionValue, multiple],
  );

  const handleDelete = useCallback(
    (toDelete: TOption) => {
      if (multiple) {
        const newOptions = selectedOptions.filter(
          (option) => getOptionValue(option) !== getOptionValue(toDelete),
        );
        onChange(newOptions.map(getOptionValue), newOptions);
      } else {
        onChange(null, []);
      }
    },
    [onChange, multiple, selectedOptions, getOptionValue],
  );

  const handleClear = useCallback(() => {
    if (multiple) {
      onChange([], []);
    } else {
      onChange(null, []);
    }
  }, [onChange, multiple]);

  const renderValue = useCallback(() => {
    return (
      <Chips
        isExpandable
        isClearable
        items={selectedOptions}
        getLabel={getChipLabel || getOptionLabel}
        getId={getOptionValue}
        getAvatar={getAvatar}
        onDelete={handleDelete}
        onClear={handleClear}
      />
    );
  }, [
    selectedOptions,
    handleDelete,
    handleClear,
    getOptionLabel,
    getChipLabel,
    getAvatar,
    getOptionValue,
  ]);

  const renderOption = useCallback(
    (
      optionProps: React.HTMLAttributes<HTMLLIElement>,
      option: TOption,
      state: AutocompleteRenderOptionState,
    ) => {
      const { selected, index } = state;
      const key = `listItem-${index}-${getOptionValue(option)}`;

      return (
        <li {...optionProps} key={key}>
          {multiple && (
            <Checkbox
              className={cls.Checkbox}
              size='medium'
              checked={selected}
            />
          )}
          <Typography variant='t2'>{getOptionLabel(option)}</Typography>
        </li>
      );
    },
    [getOptionLabel, getOptionValue, multiple],
  );

  const universalAutocompleteProps = {
    ...restProps,
    placeholder: _placeholder,
    renderOption: renderOptionExternal || renderOption,
    getOptionValue,
    getOptionLabel,
    disabled,
  };

  return (
    <Stack gap='.5rem'>
      {label && (
        <Typography
          variant='t2'
          color={disabled ? 'var(--color-monochrome-grey-300)' : undefined}
        >
          {label}
        </Typography>
      )}
      {topErrorMessage && (
        <Typography
          variant='t3'
          paddingLeft='0.5rem'
          color='var(--color-pink-200)'
        >
          {topErrorMessage}
        </Typography>
      )}
      {multiple ? (
        <UniversalAutocomplete<TOption, TValue>
          {...universalAutocompleteProps}
          multiple
          value={selectedOptions}
          onChangeValue={handleChangeValueMultiple}
        />
      ) : (
        <UniversalAutocomplete<TOption, TValue>
          {...universalAutocompleteProps}
          value={selectedOptions[0] || null}
          onChangeValue={handleChangeValueSingle}
        />
      )}
      {multiple && renderValue()}
    </Stack>
  );
}

import { useMutation } from '@apollo/client';
import { Stack } from '@mui/material';

import { Button } from 'shared/ui/buttons/Button';
import { Typography } from 'shared/ui/contents/Typography';

import { NotificationSetIsReadByDate } from '../../api/NotificationSetIsReadByDate.gql';
import {
  INotificationItem,
  NotificationItemsPeriodData,
} from '../../types/INotificationItem.types';
import { NotificationCard } from '../NotificationCard';
import { NotificationIndicator } from '../NotificationIndicator';

interface NotificationListPeriodProps {
  notifications: INotificationItem[];
  period: Omit<NotificationItemsPeriodData, 'notificationItemsList'>;
  label: string;
  hasNew: boolean;
  onNew: () => void;
  onClose: () => void;
  isToday?: boolean;
}

export const NotificationListPeriod = (props: NotificationListPeriodProps) => {
  const { notifications, period, label, hasNew, onNew, onClose, isToday } =
    props;
  const isRead = notifications.every((el) => el.isRead);

  const [setIsRead] = useMutation(NotificationSetIsReadByDate);

  const handleMarkAsRead = async () => {
    try {
      const res = await setIsRead({
        variables: {
          input: {
            datesRange: {
              from: period.dateFrom,
              to: period.dateTo,
            },
            isRead: !isRead,
          },
        },
      });

      if (res.errors) {
        console.error(res.errors);
        throw new Error('Не удалось отметить уведомления, как прочитанные');
      }
      onNew();
    } catch (err) {
      console.error(err);
    }
  };

  return notifications.length || (isToday && hasNew) ? (
    <Stack gap='.75rem'>
      {isToday && hasNew && (
        <Button variant='outlined' size='small' onClick={onNew}>
          Показать новые уведомления
        </Button>
      )}
      {!!notifications.length && (
        <>
          <Stack direction='row' justifyContent='space-between'>
            <Typography
              variant='h4'
              style={{
                color: isRead ? 'var(--color-monochrome-grey-400)' : undefined,
              }}
            >
              {label}
            </Typography>

            <NotificationIndicator
              isActive={!isRead}
              onClick={handleMarkAsRead}
            />
          </Stack>
          {notifications.map((el) => (
            <NotificationCard
              key={el.id}
              id={el.id}
              header={el.header}
              descriptions={el.text.descriptions}
              timestamp={el.timestamp}
              payload={el.text.payload}
              isNew={!el.isRead}
              onClose={onClose}
            />
          ))}
        </>
      )}
    </Stack>
  ) : null;
};

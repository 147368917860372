import { Timespan } from '@rsdk/common';

import { parseToken } from './parseToken';
import { IAccessToken, IRefreshToken } from './tokens.types';

type TGetExpiration = OneOfType<{
  /** Дата истечения токена (в секундах) */
  exp: number;
  /** Токен в виде строки */
  token: string;
}>;

/**
 * @private Используется только в слайсе
 */
export const getExpiration = (args: TGetExpiration) => {
  let expiresAt: number | undefined;

  if ('exp' in args) {
    expiresAt = args.exp;
  } else if ('token' in args) {
    const tokenAsObj = parseToken<IAccessToken | IRefreshToken>(args.token);
    if (tokenAsObj) expiresAt = tokenAsObj.exp;
  }

  if (expiresAt === undefined)
    throw new Error('Не удалось получить дату истечения токена');

  return new Timespan(expiresAt, 's');
};

import { PATH_TO_APP } from '../const';
import { AdminpanelRouteNames } from './adminapnel.names';

const ADMINPANEL_ROOT = `${PATH_TO_APP}/adminpanel`;

export const getRouteAdminpanelGroups = () =>
  `${ADMINPANEL_ROOT}/${AdminpanelRouteNames.ADMINPANEL_GROUPS}`;
export const getRouteAdminpanelGroup = (key: string) =>
  `${ADMINPANEL_ROOT}/${AdminpanelRouteNames.ADMINPANEL_GROUPS}/${key}`;
export const getRouteAdminpanelRoles = () =>
  `${ADMINPANEL_ROOT}/${AdminpanelRouteNames.ADMINPANEL_ROLES}`;
export const getRouteAdminpanelRole = (key: string) =>
  `${ADMINPANEL_ROOT}/${AdminpanelRouteNames.ADMINPANEL_ROLES}/${key}`;
export const getRouteAdminpanelFirmware = () =>
  `${ADMINPANEL_ROOT}/${AdminpanelRouteNames.ADMINPANEL_FIRMWARE}`;

import { useMutation } from '@apollo/client';
import {
  IlEyeClose as VisibilityOffIcon,
  IlEyeOpen as VisibilityIcon,
} from '@mc/react-icons/il';
import { InputAdornment, Stack } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { authSliceActions, getLoginValue, getOwnerId } from 'processes/auth';

import { ClientType, LoginType } from 'shared/api/__generated__';
import { LOGIN } from 'shared/api/authApi';
import { LoginRoutePaths } from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { loginFormat } from 'shared/lib/utils/loginFormat';
import { getBrowserStorage, setBrowserStorage } from 'shared/lib/utils/storage';
import { Button } from 'shared/ui/buttons/Button';
import { IconButton } from 'shared/ui/buttons/IconButton';
import { Typography } from 'shared/ui/contents/Typography';
import { TextField } from 'shared/ui/form/inputs/TextField';
import { ActionErrorChip } from 'shared/ui/helpers/ActionErrorChip';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import { renderTextFiled } from '../../lib/utils/renderTextFiled/renderTextFiled';
import cls from './ReLoginForm.module.scss';

export const ReLoginForm = () => {
  const [showPassword, _setShowPassword] = useState(false);
  const setShowPassword = useCallback(() => {
    _setShowPassword((prev) => !prev);
  }, []);

  const handleMouseEvents = (event: MouseEvent<HTMLButtonElement>) =>
    event.preventDefault();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const ownerId = useSelector(getOwnerId);
  const login = useSelector(getLoginValue);

  // const lastLoginType =
  //   (getLocalStorageValue(LAST_LOGIN_TYPE, () => {
  //       notificationsActions.showErrorNotification({
  //         message:
  //           'В вашем браузере отключена работа с LocalStorage, приложение может работать некорректно.',
  //       });
  //   }) as LoginType) || LoginType.PHONE;

  // const [activeLoginType, setActiveLoginType] = useState(lastLoginType);

  const [activeLoginType] = useState(LoginType.EMAIL);
  const [loginVal, setLoginVal] = useState<string>(login || '');
  const [passwordVal, setPasswordVal] = useState('');
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);

  // TODO: Перевести всё на useForm & Controller

  useEffect(() => {
    if (login) setLoginVal(login || '');
  }, [login]);

  const [logIn] = useMutation(LOGIN, {
    variables: {
      input: {
        clientType: ClientType.WEB,
        login: loginFormat(activeLoginType, loginVal),
        secret: `password::${passwordVal}`,
        orgUnitId: ownerId || getBrowserStorage('ORG_UNIT_ID'),
      },
    },
  });

  const textFiled = renderTextFiled({
    loginType: activeLoginType,
    value: loginVal,
    // Для релогина отключаем возможность изменения логина
    disabled: true,
    setValue: setLoginVal,
    setShowError: setIsError,
  });

  const handleSubmit = useCallback(async () => {
    setIsError(false);
    setLoading(true);
    try {
      const data = await logIn();
      const response = data.data?.auth.logIn;

      if (!response) {
        setIsError(true);
        return;
      }

      if (response.__typename === 'TokenPair') {
        const { access, refresh } = response;
        const tokens = {
          access: access.value,
          refresh: refresh.value,
        };

        setBrowserStorage('REFRESH_TOKEN_KEY', tokens.refresh);
        dispatch(authSliceActions.setTokens(tokens));

        dispatch(authSliceActions.hideLogin());
      } else {
        console.error('Тип не реализован: ', response.__typename);
      }
    } catch (err) {
      console.error(err);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  }, [logIn, dispatch]);

  return (
    <Stack className={cls.Container}>
      <Stack gap='.5rem'>
        <Typography variant='h2' align='left'>
          Сессия истекла
        </Typography>
        <Typography variant='h4' align='left'>
          Время жизни сессии истекло. Для продолжения работы введите пароль.
        </Typography>
      </Stack>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (passwordVal === '') {
            setIsPasswordEmpty(true);
            return;
          }
          setIsPasswordEmpty(false);
          handleSubmit();
        }}
      >
        <Stack gap='1rem'>
          {textFiled}

          <Stack gap='0.5rem'>
            <TextField
              error={isPasswordEmpty && !passwordVal}
              helperText={
                isPasswordEmpty && !passwordVal && 'Пароль не может быть пустым'
              }
              placeholder='Пароль'
              value={passwordVal}
              size='medium'
              onChange={(e) => setPasswordVal(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              sx={{
                '.MuiInputBase-input': {
                  height: '38px',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={setShowPassword}
                      onMouseDown={handleMouseEvents}
                      onMouseUp={handleMouseEvents}
                      edge='end'
                    >
                      <IconWrapper
                        className={cls.Icon}
                        Svg={showPassword ? VisibilityOffIcon : VisibilityIcon}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          {isError && <ActionErrorChip label='Упс! Что-то пошло не так' />}

          <Button
            type='submit'
            variant='contained'
            color='primary'
            size='extraLarge'
            loading={loading}
          >
            Войти
          </Button>
        </Stack>
      </form>

      <Stack direction='row' justifyContent='space-between'>
        <Button
          variant='text'
          color='primary'
          onClick={() => {
            window.location.href = LoginRoutePaths.getRouteLogin();
          }}
          size='medium'
        >
          Выйти из системы
        </Button>
      </Stack>
    </Stack>
  );
};

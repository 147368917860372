import { Dialog as MUIDialog, DialogProps } from '@mui/material';

import cls from './Dialog.module.scss';

interface IDialogProps extends DialogProps {
  enableBackdropClick?: boolean;
}

export const Dialog = (props: IDialogProps) => {
  const {
    enableBackdropClick = false,
    disableEnforceFocus,
    maxWidth,
    fullWidth,
    scroll,
    classes,
    children,
    onClose,
    ...anyProps
  } = props || {};

  const handlerClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (!enableBackdropClick && reason && reason === 'backdropClick') {
      return;
    }
    if (onClose) onClose(event, reason);
  };

  return (
    <MUIDialog
      {...anyProps}
      maxWidth={maxWidth ?? 'md'}
      fullWidth={fullWidth ?? true}
      scroll={scroll ?? 'body'}
      disableEnforceFocus={
        disableEnforceFocus === undefined ? false : disableEnforceFocus
      }
      classes={{
        container: cls.DialogContainer,
        paper: cls.DialogRoot,
        ...classes,
      }}
      onClose={handlerClose}
    >
      {children}
    </MUIDialog>
  );
};

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { IlClose } from '@mc/react-icons/il';
import { FormControlLabel, FormGroup, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { useNotifications } from 'processes/notifications/ui/Notifications.context';
import { getRolePermissions } from 'processes/user';

import {
  BanReason,
  ForceUnbanInput,
  InboundDocumentStatus,
  InboundDocumentType,
} from 'shared/api/__generated__';
import { PermissionType } from 'shared/api/authApi/types';
import {
  USER_FORCE_UNBAN,
  USER_GET_ACTIVE_BAN,
  USER_GET_INDOCS_FOR_UNBAN,
  USER_UNBAN,
  UserGetActiveBan,
  UserGetActiveBansItems,
  UserIndoc,
} from 'shared/api/userApi';
import { getRouteMain } from 'shared/const/router/global/global.paths';
import { formatDate } from 'shared/lib/utils/dates';
import { nameFormat } from 'shared/lib/utils/nameFormat';
import { Button } from 'shared/ui/buttons/Button';
import { IconButton } from 'shared/ui/buttons/IconButton';
import { Typography } from 'shared/ui/contents/Typography';
import { Checkbox } from 'shared/ui/form/checkboxes/Checkbox';
import { TextField } from 'shared/ui/form/inputs/TextField';
import { SearchSelect } from 'shared/ui/form/selects/SearchSelect';
import { ActionErrorChip } from 'shared/ui/helpers/ActionErrorChip';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';
import { Loader } from 'shared/ui/helpers/Loader';
import { ActionModalSwitchContainer } from 'shared/ui/modals/ActionModal';
import { Dialog } from 'shared/ui/modals/Dialog';

import { useUserContext } from '../../lib/context/userContext';
import { getBanReason } from '../../utils';
import cls from './UserUnbanModal.module.scss';
import { userUnbanRules } from './userUnbanRules';

type UserDefaultFormTypes = {
  banIds: string[];
  comment: string;
  documentId: string;
};

interface UserUnbanModalProps {
  handleClose: () => void;
  employeeId: string;
  banId: string;
  hasForceUnban?: boolean;
  currentBan: UserGetActiveBansItems[number];
}

export const UserUnbanDocuments = (props: {
  ban: UserGetActiveBan;
  control: Control<UserDefaultFormTypes>;
  onChange: (id: string) => void;
}) => {
  const { ban, control, onChange } = props;

  const base =
    (ban.sourceExam?.data.__typename === 'ProcessedExam' &&
      ban.sourceExam.data.preprocessed.base) ||
    (ban.sourceExam?.data.__typename === 'ProcessingFailure' &&
      ban.sourceExam?.data.preprocessed.base) ||
    (ban.sourceExam?.data.__typename === 'PreprocessingFailure' &&
      ban.sourceExam?.data.base);

  if (!base) return null;

  const isSystemBan = ['System', 'Bot'].includes(ban.actor.__typename);

  const actorName =
    (isSystemBan && 'Система') ||
    (ban.actor.__typename === 'Person' &&
      nameFormat(
        ban.actor.personalData.name,
        ban.actor.personalData.surname,
        ban.actor.personalData.patronymic,
        'short',
      )) ||
    '-';

  return (
    <Stack
      key={`banCheckbox-${base.id}`}
      gap='0.5rem'
      padding='0.5rem'
      direction='row'
      borderRadius='var(--border-radius-element)'
      bgcolor='var(--color-monochrome-grey-100)'
      alignItems='center'
    >
      <Controller
        name='banIds'
        control={control}
        render={({ field }) => (
          <FormControlLabel
            style={{
              marginLeft: '0',
              gap: '6px',
            }}
            control={
              <Checkbox
                {...field}
                size='medium'
                checked={field.value.includes(ban.id)}
                onChange={() => onChange(ban.id)}
              />
            }
            label={
              <Stack gap='2px'>
                <Typography variant='t1' color='var(--color-monochrome-black)'>
                  {`Осмотр №${base.serial}`}
                </Typography>

                <Stack
                  gap='1rem'
                  direction='row'
                  color='var(--color-monochrome-grey-400)'
                >
                  <Typography variant='t3'>
                    {formatDate(ban.timestamp, 'DD.MM.YYYY HH:mm:ss', {
                      withoutTz: true,
                    })}
                  </Typography>

                  <Typography variant='t3'>
                    {`Причина: ${getBanReason(ban.reason)}`}
                  </Typography>

                  <Typography variant='t3'>{actorName}</Typography>
                </Stack>
              </Stack>
            }
          />
        )}
      />
    </Stack>
  );
};

const banReasonsByDocType: Partial<Record<InboundDocumentType, BanReason[]>> = {
  [InboundDocumentType.INJURY_CERTIFICATE]: [BanReason.INJURY],
  [InboundDocumentType.HEALTH_CERTIFICATE]: [
    BanReason.INJURY,
    BanReason.COMPLAINTS,
    BanReason.SIGNS_OF_ILLNESS,
  ],
  [InboundDocumentType.CLIENT_APPEAL]: [
    BanReason.INJURY,
    BanReason.COMPLAINTS,
    BanReason.SIGNS_OF_INTOXICATION,
  ],
  [InboundDocumentType.ON_SITE_EXAM_PROTOCOL]: [
    BanReason.COMPLAINTS,
    BanReason.SIGNS_OF_ILLNESS,
    BanReason.SIGNS_OF_INTOXICATION,
  ],
};

const docTypeByBanReason: Partial<Record<BanReason, InboundDocumentType[]>> = (
  Object.keys(banReasonsByDocType) as InboundDocumentType[]
).reduce(
  (acc, docType: InboundDocumentType) => {
    const banReasons = banReasonsByDocType[docType];
    banReasons?.forEach((banReason) => {
      if (!acc[banReason]?.includes(docType)) {
        acc[banReason] = [...(acc[banReason] || []), docType];
      }
    });
    return acc;
  },
  {} as Partial<Record<BanReason, InboundDocumentType[]>>,
);

export const UserUnbanModal = ({
  handleClose,
  employeeId,
  banId,
  currentBan,
}: UserUnbanModalProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setNeedRefetchUser } = useUserContext();
  const notificationsActions = useNotifications();

  const permissions = useSelector(getRolePermissions);
  const hasForceUnban = permissions.has(PermissionType['BAN.FORCE_UNBAN']);

  const [documentsSelectItems, setDocumentsSelectItems] =
    useState<UserIndoc[]>();

  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    data: activeBans,
    loading: loadingActiveBans,
    error,
  } = useQuery(USER_GET_ACTIVE_BAN, {
    variables: {
      id: employeeId,
      pagination: {
        offset: 0,
        limit: 10,
      },
    },
  });

  const [getUserIndocsForBan, { loading }] = useLazyQuery(
    USER_GET_INDOCS_FOR_UNBAN,
  );
  const [unban] = useMutation(USER_UNBAN);
  const [forceUnban] = useMutation(USER_FORCE_UNBAN);

  const { handleSubmit, control, setValue, getValues, watch } =
    useForm<UserDefaultFormTypes>({
      defaultValues: {
        banIds: [banId],
        comment: '',
        documentId: '',
      },
      shouldFocusError: false,
      mode: 'onChange',
    });

  const documentId = watch('documentId');

  useEffect(() => {
    if (error || (!loadingActiveBans && !activeBans)) {
      console.error('error', error);

      notificationsActions.showErrorNotification({
        message: 'Не удалось загрузить информацию об активных блокировках',
      });
    }
  }, [activeBans, error, loadingActiveBans]);

  const AllBans = activeBans?.bans.getList.items;

  const restBans = useMemo(() => {
    const document = documentsSelectItems?.find(({ id }) => id === documentId);

    if (!AllBans) return null;

    return hasForceUnban
      ? AllBans.filter((ban) => ban.id !== banId)
      : AllBans.filter(
          (ban) =>
            ban.id !== banId &&
            document &&
            banReasonsByDocType[document.type]?.includes(ban.reason),
        );
  }, [AllBans, banId, documentId, documentsSelectItems, hasForceUnban]);

  useEffect(() => {
    setValue('banIds', [banId]);
  }, [banId, setValue]);

  const isDisabled = hasForceUnban ? false : documentsSelectItems?.length === 0;

  const handleCheckboxChange = (id: string) => {
    const currentBanIds = getValues('banIds');
    const updatedBanIds = currentBanIds.includes(id)
      ? currentBanIds.filter((itemId) => itemId !== id)
      : [...currentBanIds, id];

    setValue('banIds', updatedBanIds);
  };

  const onSave = useCallback(
    async (data: UserDefaultFormTypes) => {
      const input: ForceUnbanInput = {
        banIds: data.banIds,
        comment: data.comment,
        documentId: data.documentId || undefined,
      };

      const executeMutation = async (mutation: any) => {
        const res = await mutation({ variables: { input } });

        if (res.errors) {
          console.error(res.errors);
          throw new Error('Не удалось отправить');
        }
      };

      try {
        // Юзер с правами на force может не указывать документ
        const mutation = !data.documentId && hasForceUnban ? forceUnban : unban;
        await executeMutation(mutation);

        setIsOpenSuccess(true);
      } catch (err) {
        console.error(err);
        setIsError(true);

        notificationsActions.showErrorNotification({
          message: 'Не удалось выполнить запрос.',
        });
      }
    },
    [notificationsActions, forceUnban, hasForceUnban, unban],
  );

  const onClose = () => {
    handleClose();
    setIsOpenSuccess(false);
  };

  const handleStayOnUserPage = () => {
    setNeedRefetchUser(true);
    onClose();
  };

  const getDocs = useCallback(async () => {
    if (!getUserIndocsForBan) {
      return undefined;
    }
    const res = await getUserIndocsForBan({
      variables: {
        filters: {
          employee: {
            in: [employeeId],
          },
          type: {
            in: currentBan?.reason ? docTypeByBanReason[currentBan.reason] : [],
          },
          status: {
            in: [InboundDocumentStatus.ACTIVE],
          },
        },
        pagination: {
          limit: 100,
          offset: 0,
        },
      },
    });

    return res.data?.indocs.getFilteredList;
  }, [employeeId, getUserIndocsForBan, currentBan?.reason]);

  useEffect(() => {
    (async () => {
      const documentsData = await getDocs();
      if (documentsData?.items) {
        setDocumentsSelectItems(documentsData.items);
      }
    })();
  }, [employeeId, getDocs]);

  if (isOpenSuccess) {
    return (
      <ActionModalSwitchContainer
        actionType='success'
        onClose={onClose}
        SuccessModalProps={{
          title: 'Работник успешно разблокирован',
          subtitle: 'Теперь работник может приступить к прохождению осмотров',
          outlinedBtn: {
            label: 'На главную',
            onClick: () => {
              navigate(getRouteMain());
            },
          },
          containedBtn: {
            label: 'В карточку пользователя',
            onClick: handleStayOnUserPage,
          },
        }}
      />
    );
  }

  return (
    <Dialog open onClose={handleClose} classes={{ paper: cls.DialogPaper }}>
      {loading ? (
        <Loader>
          {t('Loading list')}
          ...
        </Loader>
      ) : (
        <>
          <IconButton className={cls.CloseBtn} onClick={handleClose}>
            <IconWrapper Svg={IlClose} />
          </IconButton>

          <Typography variant='h1' align='center'>
            Разблокировать пользователя
          </Typography>

          <Stack gap='1rem'>
            {documentsSelectItems && (
              <Controller
                name='documentId'
                control={control}
                rules={userUnbanRules.documentId(hasForceUnban)}
                render={({ field, fieldState }) => (
                  <SearchSelect
                    {...field}
                    label='Выберите документ'
                    disableSearch
                    options={documentsSelectItems}
                    getOptionLabel={({ name }) => name}
                    hasError={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    disabled={documentsSelectItems.length === 0 || isDisabled}
                    placeholder={
                      documentsSelectItems.length
                        ? 'Выбрать из списка документов'
                        : 'Нет доступных документов'
                    }
                    required={!hasForceUnban}
                  />
                )}
              />
            )}

            {!!restBans?.length && (
              <Stack gap='0.5rem'>
                <Typography variant='h3'>
                  Также можно разблокировать:
                </Typography>
                <FormGroup>
                  <Stack gap='0.5rem'>
                    {restBans.map((ban) => (
                      <UserUnbanDocuments
                        ban={ban}
                        control={control}
                        onChange={handleCheckboxChange}
                      />
                    ))}
                  </Stack>
                </FormGroup>
              </Stack>
            )}

            <Stack gap='0.5rem'>
              <Typography variant='t2'>Комментарий разблокировки</Typography>
              <Controller
                name='comment'
                control={control}
                rules={userUnbanRules.comment()}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size='small'
                    placeholder='Причина разблокировки'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    disabled={isDisabled}
                    required
                  />
                )}
              />
            </Stack>
          </Stack>

          <Stack direction='row' gap='1.5rem' justifyContent='center'>
            <Button color='primary' variant='outlined' onClick={handleClose}>
              Отмена
            </Button>

            <Button
              color='primary'
              variant='contained'
              onClick={handleSubmit(onSave)}
              disabled={isDisabled}
            >
              Разблокировать
            </Button>
          </Stack>

          {isError && <ActionErrorChip label='Произошла ошибка' />}
        </>
      )}
    </Dialog>
  );
};

import { Components, PaletteMode, Theme } from '@mui/material';

export const createComponents = (
  cssVar: (key: string) => string,
  mode: PaletteMode,
): Components<Omit<Theme, 'components'>> => ({
  MuiButton: {
    variants: [
      {
        props: { size: 'medium' },
        style: {
          padding: '6px 16px',
        },
      },
      {
        props: { size: 'small' },
        style: {
          minHeight: '34px',
          fontSize: '0.75rem',
          lineHeight: '2.2',
          padding: '4px 14px',
        },
      },
      {
        props: { variant: 'outlined', color: 'primary' },
        style: {
          color: mode === 'light' ? '#435DE5' : '#435DE5',
          borderColor: '#BCC7FF',
          '&:hover': {
            backgroundColor: '#E7EBFF',
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'error' },
        style: {
          color: '#FF005C',
          borderColor: '#FFADCB',
          '&:hover': {
            backgroundColor: '#FFE3ED',
          },
        },
      },
      {
        props: { variant: 'text' },
        style: {
          '&.Mui-disabled': {
            background: 'none',
          },
        },
      },
      {
        props: { variant: 'text', color: 'primary' },
        style: {
          color: '#435DE5',
          '&:hover': {
            backgroundColor: '#E7EBFF',
          },
        },
      },
      {
        props: { variant: 'text', color: 'error' },
        style: {
          color: '#FF005C',
          '&:hover': {
            backgroundColor: '#FFE3ED',
          },
        },
      },
      {
        props: { variant: 'text', color: 'neutral' },
        style: {
          color: '#656A83',
          '&:hover': {
            backgroundColor: '#F6F7FF',
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: '8px',
        boxShadow: 'none',
        lineHeight: '1.625',
        padding: '8px 24px',
        '&:hover': {
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          color: '#B5BAD0',
          borderColor: '#B5BAD0',
          backgroundColor: '#E3E5F1',
        },
      },
    },
  },

  MuiIconButton: {
    variants: [
      {
        props: { color: 'primary' },
        style: {
          '&:hover': {
            color: '#435DE5',
            backgroundColor: '435DE50A',
          },
        },
      },
      {
        props: { color: 'error' },
        style: {
          '&:hover': {
            color: '#FF005C',
            backgroundColor: 'FF005C0A',
          },
        },
      },
      {
        props: { color: 'info' },
        style: {
          '&:hover': {
            color: '#0288d1',
            backgroundColor: '0288d10A',
          },
        },
      },
      {
        props: { color: 'secondary' },
        style: {
          '&:hover': {
            color: '#05CFB7',
            backgroundColor: '05CFB70A',
          },
        },
      },
      {
        props: { color: 'success' },
        style: {
          '&:hover': {
            color: '#2E7D32',
            backgroundColor: '2E7D320A',
          },
        },
      },
      {
        props: { color: 'warning' },
        style: {
          '&:hover': {
            color: '#EBB62F',
            backgroundColor: 'EBB62F0A',
          },
        },
      },
      {
        props: { color: 'default' },
        style: {
          '&:hover': {
            color: 'rgba(0, 0, 0, 0.54)',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        color: '#B5BAD0',
      },
    },
  },

  MuiChip: {
    variants: [
      {
        props: { variant: 'filled', color: 'lightBlue', size: 'medium' },
        style: {
          '&:hover': {
            backgroundColor: '#BCC7FF',
          },
        },
      },
      {
        props: { variant: 'filled', color: 'lightBlue', size: 'small' },
        style: {
          backgroundColor: cssVar('--color-blue-000'),
          '&:hover': {
            backgroundColor: '#BCC7FF',
          },
        },
      },
      {
        props: { variant: 'filled', color: 'default', size: 'medium' },
        style: {
          backgroundColor: '#F6F7FF',
          '&:hover': {
            backgroundColor: '#E3E5F1',
          },
        },
      },
      {
        props: { variant: 'filled', color: 'default', size: 'small' },
        style: {
          backgroundColor: cssVar('--color-monochrome-grey-150'),
          color: cssVar('--color-monochrome-black'),
          '& .MuiChip-deleteIcon': {
            color: cssVar('--color-monochrome-grey-300'),
          },
          '& .MuiChip-deleteIcon:hover': {
            color: cssVar('--color-monochrome-grey-400'),
          },
        },
      },
      {
        props: {
          variant: 'filled',
          color: 'default',
          disabled: true,
        },
        style: {
          backgroundColor: '#E3E5F1',
        },
      },
    ],
    styleOverrides: {
      root: {
        fontSize: '0.75rem',
        lineHeight: '1.5',
        padding: '4px',
      },
      deleteIcon: {
        color: '#FFFFFF',
      },
    },
  },

  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: '#656A83', // grey 400

        // color
        '&.Mui-checked': {
          color: '#435DE5', // blue 100
        },
        '&.Mui-disabled': {
          color: '#B5BAD0', // grey 300
        },

        // size
        '&.small': {
          '& .MuiSvgIcon-root': {
            fontSize: '1rem',
          },
        },
        '&.medium': {
          '& .MuiSvgIcon-root': {
            fontSize: '1.25rem',
          },
        },
        '&.large': {
          '& .MuiSvgIcon-root': {
            fontSize: '1.5rem',
          },
        },
      },
    },
  },

  MuiTextField: {
    variants: [
      {
        props: { size: 'small' },
        style: {
          '& .MuiInputBase-input': {
            padding: '9px 12px',
            height: '22px',
            minHeight: '22px',
            fontSize: '16px',
            lineHeight: '22px',
          },
        },
      },
      {
        props: { size: 'medium' },
        style: {
          '& .MuiInputBase-input': {
            padding: '16px 12px',
            height: '22px',
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        color: '#656A83',
        '& .MuiInputBase-root': {
          fontSize: '1rem',
          lineHeight: 1.375,
          borderRadius: '8px',
          overflow: 'hidden',
        },
        '& .MuiInputLabel-root': {
          fontSize: '1rem',
          lineHeight: 1.375,
        },
        '& .MuiInputBase-root.Mui-focused': {
          '& > fieldset': {
            borderWidth: '1px',
          },
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: '#B5BAD0',
        },
        '& .MuiInputBase-adornedEnd': {
          paddingRight: 0,
        },
        '& .MuiInputAdornment-root': {
          position: 'absolute',
          top: '50%',
          right: '1rem',
        },
        '& .MuiInputBase-input:has(+ .MuiInputAdornment-root)': {
          paddingRight: '50px',
        },
      },
    },
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: {
        lineHeight: 1.375,
        marginLeft: '0',
      },
    },
  },

  MuiRadio: {
    styleOverrides: {
      root: {
        color: '#B5BAD0',
        '&.small': {
          '& .MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
            fontSize: '1rem',
          },
        },
        '&.medium': {
          '& .MuiSvgIcon-root': {
            width: '20px',
            height: '20xp',
            fontSize: '1.25rem',
          },
        },
        '&.large': {
          '& .MuiSvgIcon-root': {
            width: '24px',
            height: '24xp',
            fontSize: '1.5rem',
          },
        },
      },
    },
  },

  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: '#24252D',
        borderRadius: '8px',
        padding: '0.5rem',
        // whiteSpace: 'nowrap',
      },
    },
  },

  MuiAlert: {
    styleOverrides: {
      root: {
        color: '#656A83',
        fontSize: '0.875rem',
        '& .MuiAlertTitle-root': {
          fontSize: '1rem',
          lineHeight: 1.375,
          color: '#0E0E10',
        },
        '& .MuiAlert-icon': {
          padding: 0,
        },
      },
    },
  },

  MuiPopover: {
    styleOverrides: {
      paper: {
        marginTop: '0.25rem',
        borderRadius: '8px',
        boxShadow: '0px 2px 8px 0 rgba(5, 6, 49, 0.14)',
      },
    },
  },

  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        marginTop: '0.25rem',
        borderRadius: '8px',
        boxShadow: '0px 2px 8px 0 rgba(5, 6, 49, 0.14)',
      },
    },
  },

  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&:hover': {
          // backgroundColor: 'var(--color-monochrome-grey-100)',
          // backgroundColor: 'var(--color-blue-050)',
          // color: 'var(--color-blue-200)',
        },
      },
    },
  },

  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
});

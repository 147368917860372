/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccountType, OrgUnitPreview } from 'shared/api/__generated__';
import { GetMeQuery } from 'shared/api/__generated__/graphql';
import { TFetchStatuses } from 'shared/api/types/fetchStatuses';
import {
  clearUserFromSentry,
  setUserToSentry,
} from 'shared/lib/external/sentry';

type TGetMe = GetMeQuery['accounts']['getMe'];

interface IUserSlice {
  id: string;
  personalData: {
    name: string;
    surname: string;
    patronymic: string;
  };
  owner: {
    name: string;
    shortname: string;
  };
  currentPhoto: {
    url: Nullable<string>;
  };
  role: {
    name: Nullable<string>;
    type: Nullable<AccountType>;
    permissions: {
      key: string;
      description: string;
    }[];
  };
  accessLinks: { id: OrgUnitPreview['id'] }[];

  isLoading: TFetchStatuses;
  error?: string;
}

const initialState: IUserSlice = {
  id: '',
  personalData: {
    name: '',
    surname: '',
    patronymic: '',
  },
  owner: {
    name: '',
    shortname: '',
  },
  currentPhoto: {
    url: null,
  },
  role: {
    name: null,
    type: null,
    permissions: [],
  },
  accessLinks: [],
  isLoading: 'idle',
  error: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<TGetMe>) => {
      const { payload } = action;

      setUserToSentry({
        user: {
          id: payload.id,
          email: payload.email,
        },
        role: {
          _type: payload.role.type,
          name: payload.role.name,
        },
        owner: {
          id: payload.owner.id,
          shortname: payload.owner.displayShortname,
        },
      });

      state.id = payload.id;
      state.personalData = {
        name: payload.personalData.name,
        surname: payload.personalData.surname,
        patronymic: payload.personalData.patronymic,
      };
      state.owner = {
        name: payload.owner.displayShortname,
        shortname: payload.owner.displayShortname,
      };
      state.currentPhoto = {
        url: payload.currentPhoto?.url,
      };
      state.role = {
        name: payload.role.name,
        type: payload.role.type,
        permissions: payload.role.permissions,
      };
      state.accessLinks = payload.accessLinks;
    },
    removeUser: () => {
      clearUserFromSentry();

      return initialState;
    },
    setIsLoading: (state, action: PayloadAction<TFetchStatuses>) => {
      const { payload } = action;

      state.isLoading = payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      state.error = payload;
    },
  },
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;

import { PhotoDefect } from '../../__generated__';

export const UserMappedPhotoDefects = {
  [PhotoDefect.CROPPED_FACE]: 'Обрезанное лицо',
  [PhotoDefect.ILLUMINATION]: 'Засветка',
  [PhotoDefect.LOW_LIGHT]: 'Низкая освещённость',
  [PhotoDefect.LOW_RESOLUTION]: 'Низкое разрешение',
  [PhotoDefect.MANY_FACES]: 'Посторонние в кадре',
  [PhotoDefect.NO_FACE]: 'Нет лица',
};

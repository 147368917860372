import { TypedDocumentNode, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';

import { isEqual } from '../../utils/isEqual';
import { prepareServerVars } from '../../utils/prepareServerVars';

export function useApolloListQuery<TData, TVariables, TItem>(
  query: TypedDocumentNode<TData, TVariables>,
  variables: TVariables & {
    filters?: Record<string, unknown>;
    pagination: {
      limit: number;
      offset: number;
    };
    group?: boolean;
  },
  transform: (data: TData) => {
    items: TItem[];
    total: number;
  },
  options?: { pollInterval?: number; skip?: boolean },
) {
  const { pollInterval, skip } = options || {};
  const [data, setData] = useState({
    items: [] as TItem[],
    total: 0,
  });

  const [lastVariables, setLastVariables] = useState(variables);
  // Определяет завершился ли запрос и его обработка
  const [isCompleted, setIsCompleted] = useState(false);

  const preparedVars = useMemo(
    () => ({
      ...variables,
      filters: variables.filters
        ? prepareServerVars(variables.filters)
        : undefined,
    }),
    [variables],
  );

  const apolloHookData = useQuery(query, {
    variables: preparedVars,
    onCompleted: (res: TData) => {
      const transformed = transform(res);

      setData((prev) => {
        return {
          items: [
            ...(preparedVars.pagination.offset !== 0 ? prev.items : []),
            ...(transformed.items || []),
          ],
          total: transformed.total,
        };
      });
      setIsCompleted(true);
    },
    skip,
  });

  const allPagesQueryOptions = useMemo(
    () => ({
      variables: {
        ...preparedVars,
        pagination: {
          limit: data.items.length,
          offset: 0,
        },
      },
      onCompleted: (res: TData) => {
        const transformed = transform(res);

        setData({
          items: transformed.items,
          total: transformed.total,
        });
        setIsCompleted(true);
      },
    }),
    [preparedVars, data, transform],
  );

  // поллинг всех загруженных страниц
  useQuery(query, {
    ...allPagesQueryOptions,
    skip: !pollInterval || skip,
    pollInterval,
  });

  // для ручной перезагрузки всех загруженных страниц
  const [refetchAll] = useLazyQuery(query, allPagesQueryOptions);

  useEffect(() => {
    // При изменении фильтров сбрасываем данные
    if (!isEqual(variables.filters, lastVariables.filters)) {
      setIsCompleted(false);
      setData({
        items: [],
        total: 0,
      });
      setLastVariables(variables);
    }
  }, [variables, lastVariables]);

  useEffect(() => {
    // При изменении группировки сбрасываем данные
    if (!isEqual(variables.group, lastVariables.group)) {
      setIsCompleted(false);
      setData({
        items: [],
        total: 0,
      });
      setLastVariables(variables);
    }
  }, [variables, lastVariables]);

  return {
    ...apolloHookData,
    data,
    isFullLoaded: apolloHookData.called && data.items.length === data.total,
    isCompleted,
    refetchAll,
  };
}

import { DefaultOptions } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

if (import.meta.env.__DEV__) {
  // Чтобы не ходить на сайт Apollo подключаем сообщения об ошибках в bundle.
  loadDevMessages();
  loadErrorMessages();
}

export const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

import { useEffect, useState } from 'react';

import { hostCrashlyticsGetList } from 'entities/Host';

import { HostReportStatus, HostReportType } from 'shared/api/__generated__';
import { useApolloListQuery } from 'shared/lib/hooks/useApolloListQuery';

export const useIsTherePendingLogReport = (
  hostId: string,
  options: {
    onPendingFinish: () => void;
  },
) => {
  const { onPendingFinish } = options;
  const variables = {
    filters: {
      hostId,
      type: [HostReportType.LOG],
      status: [HostReportStatus.PENDING],
    },
    pagination: {
      limit: 1,
      offset: 0,
    },
  };

  const [isTherePendingLogReport, setIsTherePendingLogReport] = useState(false);

  const { data, refetch } = useApolloListQuery(
    hostCrashlyticsGetList,
    variables,
    (res) => {
      const page = res?.crashlytics.getList;

      return {
        items: page?.items || [],
        total: page?.total || 0,
      };
    },
    { pollInterval: isTherePendingLogReport ? 10000 : undefined },
  );

  useEffect(() => {
    setIsTherePendingLogReport(!!data?.items.length);
  }, [data]);

  useEffect(() => {
    if (!isTherePendingLogReport) {
      onPendingFinish();
    }
  }, [isTherePendingLogReport]);

  return { isTherePendingLogReport, refetchLastLogReport: refetch };
};

import { Stack, SwitchProps as MUISwitchProps } from '@mui/material';
import { useCallback } from 'react';

import { Typography } from '../../../contents/Typography';
import cls from './Switch.module.scss';
import { SwitchStyled } from './SwitchStyled';

interface SwitchProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
  size?: MUISwitchProps['size'];
}

export const Switch = (props: SwitchProps) => {
  const { label, checked, disabled, onChange, size } = props;

  const handlerChange = useCallback(() => {
    if (onChange && !disabled) onChange(!checked);
  }, [checked, disabled, onChange]);

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap='4px'
      onClick={(e) => {
        e.preventDefault();
        handlerChange();
      }}
      className={cls.SwitchContainer}
    >
      <SwitchStyled {...props} onChange={handlerChange} size={size} />
      {label && (
        <Typography className={cls.Label} variant='t3'>
          {label}
        </Typography>
      )}
    </Stack>
  );
};

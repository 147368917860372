import { IlCheck, IlClose, IlTrash2 } from '@mc/react-icons/il';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';

import { classNames } from 'shared/lib/utils/classNames';

import { Button } from '../../../buttons/Button';
import { IconButton } from '../../../buttons/IconButton';
import { Typography } from '../../../contents/Typography';
import { ActionErrorChip } from '../../../helpers/ActionErrorChip';
import { IconWrapper } from '../../../helpers/IconWrapper';
import { Dialog } from '../../Dialog';
import { TActionModal } from '../types';
import cls from './ActionModal.module.scss';

interface IActionBtn {
  label: string;
  onClick: (() => void) | ((event: React.MouseEvent<HTMLElement>) => void);
  type?: TActionModal;
  disabled?: boolean;
}

export interface ActionModalProps {
  actionType: TActionModal;
  open: boolean;
  onClose: () => void;
  title?: string;
  subtitle?: string;
  content?: ReactNode;
  outlinedBtn?: IActionBtn;
  containedBtn?: IActionBtn;
  isError?: boolean;
  containerClassName?: string;
  showCloseBtn?: boolean;
  enableBackdropClick?: boolean;
}

const ActionButtons = (props: {
  actionType: TActionModal;
  outlinedBtn?: IActionBtn;
  containedBtn?: IActionBtn;
}) => {
  const { outlinedBtn, containedBtn, actionType } = props;

  return (
    <Stack direction='row' justifyContent='center' gap='1.5rem'>
      {outlinedBtn && (
        <Button
          color='primary'
          variant='outlined'
          onClick={outlinedBtn.onClick}
          disabled={outlinedBtn.disabled}
        >
          {outlinedBtn?.label}
        </Button>
      )}

      {containedBtn && (
        <Button
          color={
            actionType === 'confirm' || actionType === 'confirm_delete'
              ? 'error'
              : 'primary'
          }
          variant='contained'
          onClick={containedBtn.onClick}
          disabled={containedBtn.disabled}
        >
          {containedBtn.label}
        </Button>
      )}
    </Stack>
  );
};

export const ActionModal = (props: ActionModalProps) => {
  const {
    open,
    onClose,
    actionType,
    title,
    subtitle,
    content,
    outlinedBtn,
    containedBtn,
    isError,
    containerClassName,
    showCloseBtn = true,
    enableBackdropClick = false,
  } = props;

  let defImage;

  switch (actionType) {
    case 'confirm':
      defImage = IlClose;
      break;
    case 'confirm_delete':
      defImage = IlTrash2;
      break;
    case 'error':
      defImage = IlClose;
      break;
    default:
      defImage = IlCheck;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      enableBackdropClick={enableBackdropClick}
    >
      <Stack
        className={classNames('', {}, [containerClassName])}
        alignItems='center'
        p='2rem 6rem'
        gap='2.5rem'
      >
        {showCloseBtn && (
          <IconButton onClick={onClose} className={cls.CloseBtn}>
            <IconWrapper Svg={IlClose} />
          </IconButton>
        )}

        <Stack alignItems='center' textAlign='center' gap='1rem'>
          {actionType !== 'action' && (
            <IconWrapper
              Svg={defImage}
              className={
                actionType === 'confirm' || actionType === 'confirm_delete'
                  ? cls.ConfirmIcon
                  : cls.SuccessIcon
              }
            />
          )}
          {title && <Typography variant='h1'>{title}</Typography>}

          {subtitle && <Typography variant='h3'>{subtitle}</Typography>}
        </Stack>

        {content && <Stack width='100%'>{content}</Stack>}

        {isError && <ActionErrorChip />}

        {isError ? (
          <Button color='primary' variant='outlined' onClick={onClose}>
            Закрыть
          </Button>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {(outlinedBtn || containedBtn) && (
              <ActionButtons
                outlinedBtn={outlinedBtn}
                containedBtn={containedBtn}
                actionType={actionType}
              />
            )}
          </>
        )}
      </Stack>
    </Dialog>
  );
};

import ReactInputMask from 'react-input-mask';

import { LoginType } from 'shared/api/__generated__';
import { TextField } from 'shared/ui/form/inputs/TextField';

interface RenderTextFiledProps {
  loginType: LoginType;
  value: string;
  disabled: boolean;
  setValue: (value: string) => void;
  setShowError: (value: boolean) => void;
}

export const renderTextFiled = ({
  loginType,
  value: arg,
  disabled,
  setValue,
  setShowError,
}: RenderTextFiledProps) => {
  switch (loginType) {
    case LoginType.PHONE:
      return (
        <ReactInputMask
          mask='+7 (999) 999-99-99'
          placeholder='+7 (000) 000-00-00'
          value={arg}
          onChange={(e) => {
            setValue(e.target.value);
            setShowError(false);
          }}
          disabled={disabled}
        >
          <TextField
            size='medium'
            sx={{
              '.MuiInputBase-input': {
                height: '38px',
              },
            }}
          />
        </ReactInputMask>
      );
    case LoginType.EMAIL:
      return (
        <TextField
          placeholder='Введите Email'
          value={arg}
          size='medium'
          onChange={(e) => {
            setValue(e.target.value);
            setShowError(false);
          }}
          sx={{
            '.MuiInputBase-input': {
              height: '38px',
            },
          }}
          disabled={disabled}
        />
      );
    case LoginType.SNILS:
      return (
        <ReactInputMask
          mask='999-999-999 99'
          maskPlaceholder='X'
          placeholder='XXX-XXX-XXX XX'
          value={arg}
          onChange={(e) => {
            setValue(e.target.value);
            setShowError(false);
          }}
          disabled={disabled}
        >
          <TextField
            size='medium'
            sx={{
              '.MuiInputBase-input': {
                height: '38px',
              },
            }}
          />
        </ReactInputMask>
      );
    case LoginType.DRIVERS_LICENSE:
      return (
        <ReactInputMask
          mask='99 99   999999'
          maskPlaceholder='X'
          placeholder='XX XX   XXXXXX'
          value={arg}
          onChange={(e) => {
            setValue(e.target.value);
            setShowError(false);
          }}
          disabled={disabled}
        >
          <TextField
            size='medium'
            sx={{
              '.MuiInputBase-input': {
                height: '38px',
              },
            }}
          />
        </ReactInputMask>
      );

    default:
      return null;
  }
};

/* eslint-disable no-param-reassign */
import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

import {
  AccountsFilters,
  HostFilters,
  HostReportsFilters,
  OrgUnitsFilters,
} from 'shared/api/__generated__';

import {
  examsListFilterInitialState,
  examsListFilterSelectedItemsInitialState,
} from '../const';
import { ExamsListFilterSelectedItems, FiltersSliceState } from '../types';

const initialState: FiltersSliceState = {
  examsList: examsListFilterInitialState,
  examsListSelectedItems: examsListFilterSelectedItemsInitialState,
  hostReportList: {
    type: [],
    hostId: '',
  } as Partial<HostReportsFilters>,
  hostsList: {
    all: { enrollmentStatus: {}, search: '' },
  } as Record<string, HostFilters>,
  orgUnitsList: {
    all: {
      search: '',
      isCounterparty: null,
      isReleasePoint: null,
    },
  } as Record<string, OrgUnitsFilters>,
  usersList: {
    all: {
      search: '',
      status: {},
    },
  } as Record<string, AccountsFilters>,
};

const filtersSlice = createSlice<
  FiltersSliceState,
  SliceCaseReducers<FiltersSliceState>
>({
  name: 'filters',
  initialState,
  reducers: {
    changeExamsListFilters: (state, { payload }) => {
      state.examsList = { ...state.examsList, ...payload };
    },
    setExamsListFilterSelectedItems: (
      state,
      action: PayloadAction<Maybe<Partial<ExamsListFilterSelectedItems>>>,
    ) => {
      state.examsListSelectedItems = {
        ...state.examsListSelectedItems,
        ...(action.payload || {}),
      };
    },
    changeHostsListFilters: (state, { payload }) => {
      const orgUnitPageFilter =
        payload.page === 'all' ? {} : { owner: { in: [payload.page] } };
      state.hostsList = {
        ...state.hostsList,
        [payload.page]: {
          ...state.hostsList[payload.page],
          ...{ ...payload.value, ...orgUnitPageFilter },
        },
      };
    },
    changeOrgUnitsListFilters: (state, { payload }) => {
      const orgUnitPageFilter =
        payload.page === 'all' ? {} : { subtree: { in: [payload.page] } };
      state.orgUnitsList = {
        ...state.orgUnitsList,
        [payload.page]: {
          ...state.orgUnitsList[payload.page],
          ...{ ...payload.value, ...orgUnitPageFilter },
        },
      };
    },
    changeUsersListFilters: (state, { payload }) => {
      const orgUnitPageFilter =
        payload.page === 'all' ? {} : { subtree: { in: [payload.page] } };
      state.usersList = {
        ...state.usersList,
        [payload.page]: {
          ...state.usersList[payload.page],
          ...{ ...payload.value, ...orgUnitPageFilter },
        },
      };
    },
    changeHostTypeReportListFilters: (state, { payload }) => {
      state.hostReportList = {
        type: payload.value,
        hostId: payload.hostId,
      };
    },
    // функция для сброса фильтров при переходе в другую карточку ПАК
    resetHostTypeReportListFilters: (state, { payload }) => {
      // если хост айди пуст, то добавляем туда айди нашего ПАК,
      // это необходимо для идентификации его в будущем
      if (state.hostReportList.hostId === '') {
        state.hostReportList = {
          hostId: payload,
        };
      }
      // если хост айди не пуст и этот айди не совпадает с текущим, то обновляем его и сбрасываем фильтры
      if (
        state.hostReportList.hostId !== '' &&
        payload !== state.hostReportList.hostId
      ) {
        state.hostReportList = {
          type: [],
          hostId: payload,
        };
      }
    },
  },
});

export const filtersSliceReducer = filtersSlice.reducer;
export const filtersSliceActions = filtersSlice.actions;

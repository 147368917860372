import { unreachable } from '@rsdk/common';

import { createNewDate } from '../createNewDate';

type DateStart = Date;
type DateEnd = Date;

type TRange =
  | 'allTheTime'
  | 'currentMonth'
  | 'currentWeek'
  | 'last24Hours'
  | 'lastEightHours'
  | 'lastHour'
  | 'lastTwoDays'
  | 'previousMonth'
  | 'previousWeek'
  | 'today'
  | 'yesterday';

/**
 * Получить дату начала и конца периода
 */
export const getDateRange = (range: TRange = 'today'): [DateStart, DateEnd] => {
  const today = createNewDate(new Date());

  switch (range) {
    case 'lastHour': {
      const start = today.startOf('minute').subtract(1, 'hour');
      const end = today.endOf('minute');

      return [start.toDate(), end.toDate()];
    }

    case 'lastEightHours': {
      const start = today.startOf('minute').subtract(8, 'hour');
      const end = today.endOf('minute');

      return [start.toDate(), end.toDate()];
    }

    case 'last24Hours': {
      const start = today.startOf('minute').subtract(24, 'hour');
      const end = today.endOf('minute');

      return [start.toDate(), end.toDate()];
    }

    case 'today': {
      return [today.startOf('day').toDate(), today.endOf('day').toDate()];
    }

    case 'lastTwoDays': {
      const start = today.startOf('minute').subtract(2, 'day');
      const end = today.endOf('minute');

      return [start.toDate(), end.toDate()];
    }

    case 'yesterday': {
      const yesterday = today.subtract(1, 'day');

      return [
        yesterday.startOf('day').toDate(),
        yesterday.endOf('day').toDate(),
      ];
    }

    case 'currentWeek': {
      const startOfCurrentWeek = today.startOf('minute').subtract(7, 'day');
      const endOfCurrentWeek = today.endOf('minute');

      return [startOfCurrentWeek.toDate(), endOfCurrentWeek.toDate()];
    }

    case 'previousWeek': {
      const startOfPreviousWeek = today.startOf('week').subtract(1, 'week');
      const endOfPreviousWeek = today.endOf('week').subtract(1, 'week');

      return [startOfPreviousWeek.toDate(), endOfPreviousWeek.toDate()];
    }

    case 'currentMonth': {
      return [today.startOf('month').toDate(), today.toDate()];
    }

    case 'previousMonth': {
      const startOfPreviousMonth = today.startOf('month').subtract(1, 'month');
      const endOfPreviousMonth = today.endOf('month').subtract(1, 'month');

      return [startOfPreviousMonth.toDate(), endOfPreviousMonth.toDate()];
    }

    case 'allTheTime': {
      const start = today.startOf('year').subtract(100, 'year');
      const end = today.endOf('minute');
      return [start.toDate(), end.toDate()];
    }

    default:
      return unreachable(range);
  }
};
